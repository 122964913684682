export default {
  // LOGIN TYPES
  LOGIN_REQUEST: "LOGIN_REQUEST",
  LOGIN_RESPONSE: "LOGIN_RESPONSE",
  LOGIN_RES_CLEAR: "LOGIN_RES_CLEAR",

  // REGISTER TYPES
  REGISTER_REQUEST: "REGISTER_REQUEST",
  REGISTER_RESPONSE: "REGISTER_RESPONSE",
  REGISTER_RES_CLEAR: "REGISTER_RES_CLEAR",

  // LOGOUT TYPES
  LOGOUT_REQUEST: "LOGOUT_REQUEST",
  LOGOUT_RESPONSE: "LOGOUT_RESPONSE",
  LOGOUT_RES_CLEAR: "LOGOUT_RES_CLEAR",

  //CLEAR USER DATA
  CLEAR_USER_DATA: "CLEAR_USER_DATA",

  // GET CURRENT USER TYPES
  GET_CURRENT_USER_REQUEST: "GET_CURRENT_USER_REQUEST",
  GET_CURRENT_USER_RESPONSE: "GET_CURRENT_USER_RESPONSE",

  //FORGOT PASSWORD
  FORGOT_PASSWORD_REQUEST: "FORGOT_PASSWORD_REQUEST",
  FORGOT_PASSWORD_RESPONSE: "FORGOT_PASSWORD_RESPONSE",
  FORGOT_PASSWORD_RES_CLEAR: "FORGOT_PASSWORD_RES_CLEAR",

  //RESET PASSWORD
  RESET_PASSWORD_REQUEST: "RESET_PASSWORD_REQUEST",
  RESET_PASSWORD_RESPONSE: "RESET_PASSWORD_RESPONSE",
  RESET_PASSWORD_RES_CLEAR: "RESET_PASSWORD_RES_CLEAR",

  //CHANGE PASSWORD
  CHANGE_PASSWORD_REQUEST: "CHANGE_PASSWORD_REQUEST",
  CHANGE_PASSWORD_RESPONSE: "CHANGE_PASSWORD_RESPONSE",
  CHANGE_PASSWORD_RES_CLEAR: "CHANGE_PASSWORD_RES_CLEAR",
};

//UNAUTHORISED DATA
export const UNAUTH_RESPONSE = "UNAUTH_RESPONSE";
export const CLEAR_UNAUTH_DATA = "CLEAR_UNAUTH_DATA";
