import React from "react";
import "./index.css";
import CustomButton from "../../Atoms/CustomButtoncon";

const CustomCancelSubmit = ({
  cancelLabel = "Cancel",
  submitLabel = "Save",
  handleClose,
  handleSubmit,
  type = true,
}) => {
  return (
    <div className="inside_buttons popup_button">
      <CustomButton
        text={cancelLabel}
        btnType={"secondary"}
        size="large"
        onClick={handleClose}
      >
        {cancelLabel}
      </CustomButton>
      <CustomButton
        size="large"
        btnType={"primary"}
        text={submitLabel}
        type={type ? "submit" : ""}
        onClick={handleSubmit}
      >
        {submitLabel}
      </CustomButton>
    </div>
  );
};

export default CustomCancelSubmit;
