import { call, put, takeLatest } from "redux-saga/effects";
import { axiosGet, axiosPost, axiosPut, setAuthToken } from "../../Utils/axios";
import ActionTypes from "./LoginActionTypes";
import {
  changePasswordResponse,
  forgotPasswordResponse,
  getCurrentUserResponse,
  loginResponse,
  logoutResponse,
  registerResponse,
  resetPasswordResponse,
  unAuthorizedData,
} from "./LoginActions";

// LOGIN
function* onloginAsync({ payload }) {
  try {
    const response = yield call(() =>
      axiosPost("auth/admin/login", payload).then((res) => res.data)
    );

    if (response?.type === "success") {
      localStorage.setItem("jwt", response?.data);
      setAuthToken(response?.data);
    }
    yield put(loginResponse(response));
  } catch (error) {
    yield put(loginResponse(error));
  }
}

// REGISTER
function* onRegisterAsync({ payload }) {
  try {
    const response = yield call(() =>
      axiosPost("register", payload).then((res) => res.data)
    );
    yield put(registerResponse(response));
  } catch (error) {
    //if self register
    yield put(registerResponse(error));
    //if admin register
    // yield put(unAuthorizedData(error));
  }
}

// LOGOUT
function* onLogoutAsync({ payload }) {
  try {
    const response = yield call(() =>
      axiosPost(`logout`, payload).then((res) => res.data)
    );
    yield put(logoutResponse(response));
  } catch (error) {
    yield put(unAuthorizedData(error));
  }
}

// GET CURRENT USER
function* onGetCurrentUserAsync() {
  try {
    const response = yield call(() =>
      axiosGet("auth/admin/get_user").then((res) => res.data)
    );
    yield put(getCurrentUserResponse(response));
  } catch (error) {
    yield put(unAuthorizedData(error));
  }
}

// FORGOT PASSWORD
function* onForgotPasswordAsync({ payload }) {
  try {
    const response = yield call(() =>
      axiosPost("auth/admin/forgot_password", payload).then((res) => res.data)
    );
    yield put(forgotPasswordResponse(response));
  } catch (error) {
    yield put(forgotPasswordResponse(error));
  }
}

// RESET PASSWORD
function* onResetPasswordAsync({ payload }) {
  try {
    const response = yield call(() =>
      axiosPost(
        `auth/admin/reset_password?token=${payload.token}&id=${payload.id}`,
        payload
      ).then((res) => res.data)
    );
    yield put(resetPasswordResponse(response));
  } catch (error) {
    yield put(resetPasswordResponse(error));
  }
}

// CHANGE PASSWORD
function* onChangePasswordAsync({ payload, id }) {
  try {
    const response = yield call(() =>
      axiosPut(`admin/changepassword/${id}`, payload).then((res) => res.data)
    );
    yield put(changePasswordResponse(response));
  } catch (error) {
    yield put(unAuthorizedData(error));
  }
}

//WATCHER
export function* authWatcherSaga() {
  yield takeLatest(ActionTypes.LOGIN_REQUEST, onloginAsync);
  yield takeLatest(ActionTypes.REGISTER_REQUEST, onRegisterAsync);
  yield takeLatest(ActionTypes.LOGOUT_REQUEST, onLogoutAsync);
  yield takeLatest(ActionTypes.GET_CURRENT_USER_REQUEST, onGetCurrentUserAsync);
  yield takeLatest(ActionTypes.FORGOT_PASSWORD_REQUEST, onForgotPasswordAsync);
  yield takeLatest(ActionTypes.RESET_PASSWORD_REQUEST, onResetPasswordAsync);
  yield takeLatest(ActionTypes.CHANGE_PASSWORD_REQUEST, onChangePasswordAsync);
}
