export default {
  GET_ADMINUSER_LIST_REQUEST: "GET_ADMINUSER_LIST_REQUEST",
  GET_ADMINUSER_LIST_RESPONSE: "GET_ADMINUSER_LIST_RESPONSE",

  GET_ADMINUSER_BY_ID_REQUEST: "GET_ADMINUSER_BY_ID_REQUEST",
  GET_ADMINUSER_BY_ID_RESPONSE: "GET_ADMINUSER_BY_ID_RESPONSE",
  GET_ADMINUSER_BY_ID_RESPONSE_CLEAR: "GET_ADMINUSER_BY_ID_RESPONSE_CLEAR",

  POST_ADMINUSER_REQUEST: "POST_ADMINUSER_REQUEST",
  POST_ADMINUSER_RESPONSE: "POST_ADMINUSER_RESPONSE",
  POST_ADMINUSER_RESPONSE_CLEAR: "POST_ADMINUSER_RESPONSE_CLEAR",

  PUT_ADMINUSER_REQUEST: "PUT_ADMINUSER_REQUEST",
  PUT_ADMINUSER_RESPONSE: "PUT_ADMINUSER_RESPONSE",
  PUT_ADMINUSER_RESPONSE_CLEAR: "PUT_ADMINUSER_RESPONSE_CLEAR",

  PUT_USER_REQUEST: "PUT_USER_REQUEST",
  PUT_USER_RESPONSE: "PUT_USER_RESPONSE",
  PUT_USER_RESPONSE_CLEAR: "PUT_USER_RESPONSE_CLEAR",

  DELETE_ADMINUSER_REQUEST: "DELETE_ADMINUSER_REQUEST",
  DELETE_ADMINUSER_RESPONSE: "DELETE_ADMINUSER_RESPONSE",
  DELETE_ADMINUSER_RESPONSE_CLEAR: "DELETE_ADMINUSER_RESPONSE_CLEAR",
};
