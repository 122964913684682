import React from "react";
import "./index.css";
import AddIcon from "@mui/icons-material/Add";

export default function ComponetTitle(props) {
  return (
    <div
      onClick={props.onClick}
      style={{
        marginTop: props.mt,
        marginBottom: props.mb,
        cursor: props.onClick ? "pointer" : "",
      }}
      className="componet_title_container"
    >
      {props.accordion && (
        <div style={{ display: "flex", alignItems: "center" }}>
          {props.children}
          <h4 className="componet-title">
            {props?.title}{" "}
            {props?.span ? <span className="green">{props.span}</span> : null}
          </h4>
        </div>
      )}
      {!props.accordion && (
        <>
          <h4 className="componet-title">
            {props?.title}{" "}
            {props?.span ? <span className="green">{props.span}</span> : null}
          </h4>
          {props.children}
        </>
      )}
    </div>
  );
}
