import React, { useEffect, useState } from "react";
import "./index.css";
import Dropdown from "../../Components/Atoms/Dropdown";
import AddIcon from "@mui/icons-material/Add";

import CustomButton from "../../Components/Atoms/CustomButtoncon";
import Model from "../../Components/Organisms/ModelComponent";
import AddAdminUser from "./coms/AddAdminUser";
import TableButton from "../../Components/Molecules/TableButton";
import { TableIconConstant, sortList } from "../../Utils/constants";
import { useDispatch, useSelector } from "react-redux";
import {
  getAdminUserListRequest,
  postAdminUserResponseClear,
  putAdminUserResponseClear,
} from "../../Redux/AdminUser/AdminUserActions";
import toast from "react-hot-toast";
import NoRecords from "../../Components/Molecules/NoRecords";
import HelmetData from "../../Utils/HelmetData";
import CountPerPage from "../../Components/Organisms/CountperPage";

const AdminUser = () => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const [each, setEach] = useState(null);
  const [page, setPage] = useState(1);
  const [totalpage, setTotalpage] = useState(1);
  const [pagelimit, setPagelimit] = useState(10);
  const [quickFilter, setQuickFilter] = useState("");
  const [sortBy, setSortBy] = useState("");
  const handleClose = () => {
    setOpen(false);
  };
  useEffect(() => {
    if (open) return;
    setEach(null);
  }, [open]);
  const {
    postAdminUserResponse,
    postAdminUserLoading,
    getAdminUserResponse,
    putAdminUserLoading,
    putAdminUserResponse,
  } = useSelector((state) => state.adminuser);
  useEffect(() => {
    if (!getAdminUserResponse) return;

    setTotalpage(getAdminUserResponse?.data?.total_pages);
  }, [getAdminUserResponse]);
  useEffect(() => {
    if (putAdminUserLoading || postAdminUserLoading) return;

    dispatch(
      getAdminUserListRequest({
        quickFilter,
        filter: { sortBy, page },
        keyword: "",
      })
    );
  }, [putAdminUserLoading, postAdminUserLoading, page, pagelimit, sortBy]);
  console.log(getAdminUserResponse);
  const dispatch = useDispatch();
  useEffect(() => {
    if (!putAdminUserResponse) return;
    if (putAdminUserResponse?.type === "success") {
      toast.success("AdminUser Updated Successfully");
      handleClose();
    }
    dispatch(putAdminUserResponseClear());
  }, [putAdminUserResponse]);
  useEffect(() => {
    if (!postAdminUserResponse) return;
    if (postAdminUserResponse?.type === "success") {
      toast.success("AdminUser Created Successfully");
      handleClose();
    }

    dispatch(postAdminUserResponseClear());
  }, [postAdminUserResponse]);

  return (
    <>
      <HelmetData title="AdminUser" />
      <Model
        handleClose={handleClose}
        open={open}
        Title={each ? "Update Admin User" : "Add New Admin User"}
      >
        <AddAdminUser each={each} handleClose={handleClose} />
      </Model>
      <div className="matrial_body">
        {" "}
        <div className="filter_table_container">
          <div></div>
          <div className="sort_by_filter">
            <p>Sort By</p>{" "}
            <div className="filter_input">
              <Dropdown
                value={sortBy}
                onChange={(e) => {
                  setSortBy(e.target.value);
                }}
                name="sortBy"
                setValue="value"
                placeholder="Select"
                data_testid="role"
                required="true"
                data={sortList}
              />
            </div>
            <CustomButton
              size="medium"
              btnType={"primary"}
              buttonType={"add"}
              btnClass={"py-10 "}
              onClick={() => {
                handleOpen();
              }}
            >
              Add New AdminUser
            </CustomButton>
          </div>
        </div>
        <div className="tableWrpr">
          <table data-testid="table-container" className="table-container">
            <tbody>
              <tr className="td_header_color">
                <th className="th">Sr.No</th>

                <th className="th">AdminUser Name</th>
                <th className="th">Phone Number</th>
                <th className="th">Email </th>
                <th className="th">Actions</th>
              </tr>

              {getAdminUserResponse?.data?.length ? (
                getAdminUserResponse?.data?.map((each, index) => {
                  return (
                    <tr className="tr-container" key={index}>
                      <td className="td td-index">{index + 1}</td>

                      <td className="td">{each?.first_name}</td>
                      <td className="td">{each?.phone}</td>
                      <td className="td">{each?.email}</td>

                      <td className="td">
                        <div className="td-actions">
                          {/* <TableButton
                          buttonType={TableIconConstant.view}
                          onClickBtn={() => {
                            //navigate(`${each._id}`);
                          }}
                          size="large"
                        /> */}

                          <TableButton
                            buttonType={TableIconConstant.edit}
                            onClickBtn={
                              () => {
                                setEach(each);
                                handleOpen();
                              }
                              // navigateEdit(each._id)
                            }
                            size="large"
                          />

                          {/* <TableButton
                          size="large"
                          buttonType={TableIconConstant.status}
                          statusVal={each.status === "Active"}
                          onClickBtn={() => {
                            //   toggleEmployeeStatus(each._id);
                          }}
                        /> */}
                        </div>
                      </td>
                    </tr>
                  );
                })
              ) : (
                <NoRecords />
              )}
            </tbody>
          </table>
        </div>
        <CountPerPage
          pagelimit={pagelimit}
          totalpage={totalpage}
          setPagelimit={setPagelimit}
          setPage={setPage}
        />
      </div>
    </>
  );
};

export default AdminUser;
