import React from "react";
import "./index.css";
export default function Whitespace({ mt, mb, children, fg = 0, br, pd }) {
  return (
    <div
      style={{
        marginTop: mt,
        marginBottom: mb,
        flexGrow: fg,
        border: br,
        padding: pd,
      }}
      className="white_space_container"
    >
      {children}
    </div>
  );
}
