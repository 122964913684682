import React, { useEffect, useState } from "react";
import "./AddAdminUser.css";
import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import CustomCancelSubmit from "../../../Components/Molecules/CustomCancelSubmit";
import InputField from "../../../Components/Atoms/InputField";
import {
  postAdminUserRequest,
  putAdminUserRequest,
} from "../../../Redux/AdminUser/AdminUserActions";
import { expression, stateList, countryList } from "../../../Utils/constants";
import PhoneNumber from "../../../Components/Molecules/Phonenumber";
import Dropdown from "../../../Components/Atoms/Dropdown";

function AddAdminUser(props) {
  const dispatch = useDispatch();
  let [stateListUser, setStateListUser] = useState([]);
  const [checkphone, setCheckphone] = useState(0);
  const { each } = props;
  useEffect(() => {
    if (!each) return;
    for (let key in each) {
      formik.setFieldValue(key, each[key]);
    }
    //  formik.setFieldValue("description", each.description);
  }, [each]);
  const formik = useFormik({
    initialValues: {
      first_name: "",
      phone: "",
      email: "",
    },
    onSubmit: (values) => {
      if (each) {
        dispatch(putAdminUserRequest(values, each._id));
      } else {
        dispatch(postAdminUserRequest(values));
      }
    },
    validationSchema: Yup.object().shape({
      email: Yup.string()
        .matches(expression.email, "Invalid email")
        .matches(expression.Without_space, "Email should not contain spaces"),
      first_name: Yup.string()
        .required("Required")
        .min(3, "Name must be at least 3 characters")
        .max(50, "Name must be at most 50 characters")
        .matches(expression.char, "name should only have characters")
        .matches(/^(?!\s).+(?<!\s)$/, "name should not contain spaces"),
      phone: Yup.string().test(
        "check phone",
        "Phone number is invalid",
        (hour) => {
          return !(hour && checkphone !== hour.length);
        }
      ),
    }),
  });
  useEffect(() => {
    if (formik.values?.country) {
      const selectedCountry = countryList.find(
        (each) => each.name === formik.values?.country
      );
      console.log(selectedCountry, formik.values?.country);
      let updatedStateListUser = stateList.getStatesOfCountry(
        selectedCountry?.isoCode
      );
      setStateListUser(updatedStateListUser);
      formik.setFieldValue("state", each?.state);
    }
  }, [formik.values.country]);
  console.log(stateListUser);
  return (
    <>
      <form className="customer_model_form">
        <div className="black-ine-container"></div>
        <InputField
          value={formik.values.first_name}
          onChange={formik.handleChange}
          name="first_name"
          labelText="Name"
          placeholder="Enter Name"
          onBlur={formik.handleBlur}
          errorMsg={formik.touched.first_name && formik.errors?.first_name}
          type="text"
        />
        <div className="col_2">
          <PhoneNumber
            value={formik.values.phone}
            onChange={formik.setFieldValue}
            labelText="Phone Number"
            name="phone"
            placeholder="Enter phone number"
            // scrollIntoView={scrollIntoView}
            onBlur={formik.handleBlur}
            validcheck={setCheckphone}
            errorMsg={formik.touched.phone && formik.errors?.phone}
          />
          <InputField
            value={formik.values.email}
            onChange={formik.handleChange}
            name="email"
            labelText="Email Address"
            placeholder="Enter Email"
            onBlur={formik.handleBlur}
            errorMsg={formik.touched.email && formik.errors?.email}
            type="email"
          />
        </div>

        <Dropdown
          value={formik.values.role}
          onChange={formik.handleChange}
          labelText="Role"
          name="role"
          placeholder="Select your role"
          setValue="name"
          data_testid="role"
          errorMsg={formik.touched.role && formik.errors?.role}
          onBlur={formik.handleBlur}
          data={[
            { name: "Admin", value: " Admin" },
            { name: "Sub-Admin", value: " Sub-Admin" },
          ]}
        />
        {/* <InputField
          value={formik.values.company_name}
          onChange={formik.handleChange}
          name="company_name"
          labelText="Company Name (if applicable)"
          placeholder="Enter Company Name"
          onBlur={formik.handleBlur}
          errorMsg={formik.touched.company_name && formik.errors?.company_name}
          type="text"
        />
        <InputField
          value={formik.values.address}
          onChange={formik.handleChange}
          name="address"
          labelText="Address"
          placeholder="Enter address"
          onBlur={formik.handleBlur}
          errorMsg={formik.touched.address && formik.errors?.address}
          type="text"
        />
        <div className="col_2">
          <Dropdown
            value={formik.values.country}
            onChange={formik.handleChange}
            labelText="Country"
            name="country"
            placeholder="Select your country"
            setValue="name"
            data_testid="country"
            errorMsg={formik.touched.country && formik.errors?.country}
            onBlur={formik.handleBlur}
            data={countryList}
          />

          <Dropdown
            value={formik.values.state}
            onChange={formik.handleChange}
            labelText="State"
            name="state"
            placeholder="Select your state"
            setValue="name"
            data_testid="state"
            errorMsg={formik.touched.state && formik.errors?.state}
            onBlur={formik.handleBlur}
            data={stateListUser}
          />
          <InputField
            value={formik.values.city}
            onChange={formik.handleChange}
            name="city"
            labelText="City"
            placeholder="Enter City"
            onBlur={formik.handleBlur}
            errorMsg={formik.touched.city && formik.errors?.city}
            type="text"
          />
          <InputField
            value={formik.values.zipcode}
            onChange={formik.handleChange}
            name="zipcode"
            labelText="Zipcode"
            placeholder="Enter Zipcode"
            onBlur={formik.handleBlur}
            errorMsg={formik.touched.zipcode && formik.errors?.zipcode}
            type="text"
          />
        </div> */}
        <div className="black-ine-container"></div>
      </form>
      <CustomCancelSubmit
        cancelLabel="Cancel"
        submitLabel={each ? "Update AdminUser" : "Add AdminUser"}
        handleClose={props.handleClose}
        handleSubmit={() => formik.handleSubmit()}
      />
    </>
  );
}

export default AddAdminUser;
