import React from "react";
import "./index.css";
function DashBoardChart(props) {
  const style = {
    background: props.background,
    color: props.color,
  };
  return (
    <div style={style} className="dash_chart">
      <p className="para_dash_chart1">{props.title}</p>
      <p className="para_dash_chart2">{props.value || 0} </p>
    </div>
  );
}

export default DashBoardChart;
