import CustomerTypes from "./CustomerActionTypes";

//----------------team list GET--------------------
export const getCustomerListRequest = (payload) => ({
  type: CustomerTypes.GET_CUSTOMER_LIST_REQUEST,
  payload: payload,
});

export const getCustomerListResponse = (payload) => ({
  type: CustomerTypes.GET_CUSTOMER_LIST_RESPONSE,
  payload: payload,
});

export const getCustomerByIdRequest = (id) => ({
  type: CustomerTypes.GET_CUSTOMER_BY_ID_REQUEST,
  id: id,
});

export const getCustomerByIdResponse = (payload) => ({
  type: CustomerTypes.GET_CUSTOMER_BY_ID_RESPONSE,
  payload: payload,
});

export const getCustomerByIdResponseClear = () => ({
  type: CustomerTypes.GET_CUSTOMER_BY_ID_RESPONSE_CLEAR,
});
//----------------team POST--------------------------
export const postCustomerRequest = (payload) => ({
  type: CustomerTypes.POST_CUSTOMER_REQUEST,
  payload: payload,
});

export const postCustomerResponse = (payload) => ({
  type: CustomerTypes.POST_CUSTOMER_RESPONSE,
  payload: payload,
});

export const postCustomerResponseClear = () => ({
  type: CustomerTypes.POST_CUSTOMER_RESPONSE_CLEAR,
});

//----------------team PUT----------------------------
export const putCustomerRequest = (payload, id) => ({
  type: CustomerTypes.PUT_CUSTOMER_REQUEST,
  payload: payload,
  id: id,
});

export const putCustomerResponse = (payload) => ({
  type: CustomerTypes.PUT_CUSTOMER_RESPONSE,
  payload: payload,
});

export const putCustomerResponseClear = () => ({
  type: CustomerTypes.PUT_CUSTOMER_RESPONSE_CLEAR,
});

//--------------team DELETE----------------------------
export const deleteCustomerRequest = (id) => ({
  type: CustomerTypes.DELETE_CUSTOMER_REQUEST,
  id: id,
});

export const deleteCustomerResponse = (payload) => ({
  type: CustomerTypes.DELETE_CUSTOMER_RESPONSE,
  payload: payload,
});

export const deleteCustomerResponseClear = () => ({
  type: CustomerTypes.DELETE_CUSTOMER_RESPONSE_CLEAR,
});

//------- Put User ----
export const putUserRequest = (payload, id) => ({
  type: CustomerTypes.PUT_USER_REQUEST,
  payload: payload,
  id: id,
});

export const putUserResponse = (payload) => ({
  type: CustomerTypes.PUT_USER_RESPONSE,
  payload: payload,
});

export const putUserResponseClear = () => ({
  type: CustomerTypes.PUT_USER_RESPONSE_CLEAR,
});
