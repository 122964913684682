import ActionTypes, {
  CLEAR_UNAUTH_DATA,
  UNAUTH_RESPONSE,
} from "./LoginActionTypes";

const initialState = {
  user: null,
  userLoading: false,
  userResponse: null,

  loginLoading: false,
  loginResponse: null,

  registerLoading: false,
  registerResponse: null,

  logoutLoading: false,
  logoutResponse: null,

  forgotPasswordLoading: false,
  forgotPasswordResponse: null,

  resetPasswordLoading: false,
  resetPasswordResponse: null,

  changePasswordLoading: false,
  changePasswordResponse: null,

  unAuthorized: null,
};

const loginReducer = (state = initialState, action) => {
  switch (action.type) {
    // GET CURRENT USER
    case ActionTypes.GET_CURRENT_USER_REQUEST:
      return {
        ...state,
        userLoading: true,
      };

    case ActionTypes.GET_CURRENT_USER_RESPONSE:
      return {
        ...state,
        user: action.payload?.data,
        userLoading: false,
        userResponse: action.payload,
      };

    // LOGIN
    case ActionTypes.LOGIN_REQUEST:
      return {
        ...state,
        loginLoading: true,
      };

    case ActionTypes.LOGIN_RESPONSE:
      return {
        ...state,
        loginLoading: false,
        loginResponse: action.payload,
      };

    case ActionTypes.LOGIN_RES_CLEAR:
      return {
        ...state,
        loginResponse: null,
      };

    // REGISTER
    case ActionTypes.REGISTER_REQUEST:
      return {
        ...state,
        registerLoading: true,
      };

    case ActionTypes.REGISTER_RESPONSE:
      return {
        ...state,
        registerLoading: false,
        registerResponse: action.payload,
      };

    case ActionTypes.REGISTER_RES_CLEAR:
      return {
        ...state,
        registerResponse: null,
      };

    // LOGOUT
    case ActionTypes.LOGOUT_REQUEST:
      return {
        ...state,
        logoutLoading: true,
      };

    case ActionTypes.LOGOUT_RESPONSE:
      return {
        ...state,
        logoutLoading: false,
        logoutResponse: action.payload,
      };

    case ActionTypes.LOGOUT_RES_CLEAR:
      return {
        ...state,
        logoutResponse: null,
      };

    // CLEAR USER DATA
    case ActionTypes.CLEAR_USER_DATA:
      return {
        ...state,
        user: null,
        userLoading: false,
        userResponse: null,
      };

    // FORGOT PASSWORD
    case ActionTypes.FORGOT_PASSWORD_REQUEST:
      return {
        ...state,
        forgotPasswordLoading: true,
      };

    case ActionTypes.FORGOT_PASSWORD_RESPONSE:
      return {
        ...state,
        forgotPasswordLoading: false,
        forgotPasswordResponse: action.payload,
      };

    case ActionTypes.FORGOT_PASSWORD_RES_CLEAR:
      return {
        ...state,
        forgotPasswordResponse: null,
      };

    // RESET PASSWORD
    case ActionTypes.RESET_PASSWORD_REQUEST:
      return {
        ...state,
        resetPasswordLoading: true,
      };

    case ActionTypes.RESET_PASSWORD_RESPONSE:
      return {
        ...state,
        resetPasswordLoading: false,
        resetPasswordResponse: action.payload,
      };

    case ActionTypes.RESET_PASSWORD_RES_CLEAR:
      return {
        ...state,
        resetPasswordResponse: null,
      };

    // CHANGE PASSWORD
    case ActionTypes.CHANGE_PASSWORD_REQUEST:
      return {
        ...state,
        changePasswordLoading: true,
      };

    case ActionTypes.CHANGE_PASSWORD_RESPONSE:
      return {
        ...state,
        changePasswordLoading: false,
        changePasswordResponse: action.payload,
      };

    case ActionTypes.CHANGE_PASSWORD_RES_CLEAR:
      return {
        ...state,
        changePasswordResponse: null,
      };

    // UNAUTHORISED
    case UNAUTH_RESPONSE:
      return {
        ...state,
        unAuthorized: action.payload,
      };

    case CLEAR_UNAUTH_DATA:
      return {
        ...state,
        userLoading: false,
        userResponse: null,

        loginLoading: false,
        loginResponse: null,

        registerLoading: false,
        registerResponse: null,

        logoutLoading: false,
        logoutResponse: null,

        forgotPasswordLoading: false,
        forgotPasswordResponse: null,

        resetPasswordLoading: false,
        resetPasswordResponse: null,

        changePasswordLoading: false,
        changePasswordResponse: null,

        unAuthorized: null,
      };

    default:
      return state;
  }
};

export default loginReducer;
