import { all } from "redux-saga/effects";
import { authWatcherSaga } from "./LoginSaga/LoginSaga";

import { RentedWatcherSaga } from "./Rented/RentedSaga";
import { CustomerWatcherSaga } from "./Customer/CustomerSaga";
import { CompaniesWatcherSaga } from "./Companies/CompaniesSaga";
import { AdminUserWatcherSaga } from "./AdminUser/AdminUserSaga";
import { UsersWatcherSaga } from "./Users/UsersSaga";

export default function* RootSaga() {
  yield all([
    //AUTH WATCHER
    authWatcherSaga(),

    //COMPANY WATCHER
    CompaniesWatcherSaga(),
    UsersWatcherSaga(),

    AdminUserWatcherSaga(),
    RentedWatcherSaga(),
    CustomerWatcherSaga(),
  ]);
}
