import React, { useState } from "react";
import "./index.css";
import SettingsItemList from "../../Components/Organisms/SideSettingItemList";
import Profile from "./comps/Myprofile";
import Changepassword from "./comps/Changepass";

function Myprofile() {
  const [currTab, setCurrTab] = useState("My Profile");
  const renderCurrTab = () => {
    switch (currTab) {
      case "My Profile":
        return <Profile />;
      case "Change Password":
        return <Changepassword />;
      default:
        return null;
    }
  };
  const list = ["My Profile", "Change Password"];

  return (
    <div className="myprofile_container">
      <section className="setting_left">
        <SettingsItemList
          list={list}
          current={currTab}
          title="CONFIGURATIONS"
          page={setCurrTab}
        />
      </section>
      <section className="setting_right">{renderCurrTab()}</section>
    </div>
  );
}

export default Myprofile;
