import { combineReducers } from "@reduxjs/toolkit";
import loginReducer from "./LoginSaga/LoginReducer";
import RentedReducer from "./Rented/RentedReducer";
import CustomerReducer from "./Customer/CustomerReducer";
import CompaniesReducer from "./Companies/CompaniesReducer";
import AdminUserReducer from "./AdminUser/AdminUserReducer";
import UsersReducer from "./Users/UsersReducer";

const rootReducer = combineReducers({
  authState: loginReducer,

  companies: CompaniesReducer,
  user: UsersReducer,
  adminuser: AdminUserReducer,
  rented: RentedReducer,
  customer: CustomerReducer,
});

export default rootReducer;
