import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { useState, useEffect } from "react";

import toast from "react-hot-toast";
import * as Yup from "yup";
import { gwayloginlogo } from "../../Utils/Utils";
import "./index.css";
import { useLocation, useNavigate } from "react-router-dom";

import HelmetData from "../../Utils/HelmetData";
import InputField from "../../Components/Atoms/InputField";
import CustomButton from "../../Components/Atoms/CustomButtoncon";
import {
  getCurrentUserRequest,
  loginRequest,
  loginResClear,
} from "../../Redux/LoginSaga/LoginActions";
import { decrypt, encrypt } from "../../Utils/cryptoPassword";
import { setToken } from "../../Utils/autheticateUser";
import { CircularProgress } from "@mui/material";
import { expression } from "../../Utils/constants";
// import CustomButton from "../../components/CustomButton";

const LoginPage = () => {
  const [savePassword, setSavePassword] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const { loginResponse, user, userLoading, unAuthorized } = useSelector(
    (state) => state.authState
  );

  // const { CompanyNameResponse } = useSelector((state) => state.registerReducer);
  // const [userLocation, setUserLocation] = useState(null);
  // const { getUserResponse, getUserLoading } = useSelector(
  //   (state) => state.getUserReducer
  // );
  const formik = useFormik({
    initialValues: { email: "", password: "", company: "" },
    onSubmit: (values) => {
      if (savePassword) {
        localStorage.setItem("user_cred", encrypt(JSON.stringify(values)));
      } else {
        localStorage.removeItem("user_cred");
      }

      // const { company, ...loginCredentials } = values;
      // const payload = {
      //   ...loginCredentials,
      //   company_id: CompanyNameResponse?.data?._id,
      //   location: {
      //     coordinates: [userLocation?.latitude, userLocation?.longitude],
      //   },
      // };

      dispatch(loginRequest(values));
    },

    validationSchema: Yup.object().shape({
      // company: Yup.string().required("Required"),
      email: Yup.string()
        .required("Required")
        .matches(expression.email, "Invalid email")
        .matches(expression.space, "Email should not contain spaces"),
      password: Yup.string().required("Required"),
    }),
  });
  useEffect(() => {
    // if (getUserLoading) return;
    dispatch(getCurrentUserRequest());
    const userCred = localStorage.getItem("user_cred");
    if (userCred) {
      setSavePassword(true);
      const { email, password } = JSON?.parse(decrypt(userCred));
      // formik.setFieldValue("company", company);
      formik.setFieldValue("email", email);
      formik.setFieldValue("password", password);
    }
  }, []);
  ///check for company name is valid or not
  // useEffect(() => {
  //   let id;
  //   if (formik.values.company) {
  //     id = setTimeout(() => {
  //       dispatch(getCompanyNameRequest(formik.values.company.toLowerCase()));
  //     }, 400);
  //   }
  //   return () => clearTimeout(id);
  // }, [formik.values.company]);

  ///toast message based on user credentials
  useEffect(() => {
    if (!loginResponse) return;
    if (loginResponse?.type === "success") {
      toast.success("Login Successfully");
      // navigate("/");
      dispatch(getCurrentUserRequest());
    } else {
      toast.error(loginResponse?.response?.data?.message);
    }
    return () => {
      dispatch(loginResClear());
    };
  }, [loginResponse]);

  useEffect(() => {
    if (!user) return;
    if (location.state?.from?.pathname) {
      navigate(location.state.from.pathname);
    } else navigate("/");
  }, [user]);

  if (userLoading && !unAuthorized && !user) {
    return (
      <div className="circular_loader_container" style={{ height: "100vh" }}>
        <CircularProgress className="circular_loader" />
        Loading...
      </div>
    );
  }

  // useEffect(() => {
  //   if (CompanyNameResponse && !CompanyNameResponse?.type) {
  //     toast.error(CompanyNameResponse?.message);
  //   }
  // }, [CompanyNameResponse]);

  return (
    <>
      <HelmetData title="Login" />
      <div className="login-page-container">
        <form onSubmit={formik.handleSubmit} className="login-form">
          <img
            className="login-form-logo"
            src={gwayloginlogo}
            alt="gway-logo"
          />
          {/* <div className="login-heading-container"> */}
          <h1 className="login-heading">Login</h1>
          {/* </div> */}
          <div className="login-input-field-container">
            <InputField
              value={formik.values.email}
              onChange={formik.handleChange}
              name="email"
              labelText="Email"
              placeholder="Enter your email"
              required="true"
              onBlur={formik.handleBlur}
              errorMsg={formik.touched.email && formik.errors?.email}
              type="email"
            />
            <InputField
              value={formik.values.password}
              onChange={formik.handleChange}
              name="password"
              labelText="Password"
              placeholder="Enter password"
              required="true"
              onBlur={formik.handleBlur}
              errorMsg={formik.touched.password && formik.errors?.password}
              type="password"
            />
          </div>
          <div className="login-show-password-container">
            <div className="login-show-pass-input-container">
              <input
                onChange={() => setSavePassword((prev) => !prev)}
                className="login-show-pass-checkbox"
                id="show-password-checkbox"
                type="checkbox"
                checked={savePassword}
              />
              <label
                className="login-show-pass-checkbox-label"
                htmlFor="show-password-checkbox"
              >
                Remember me
              </label>
            </div>
            <button
              onClick={() => navigate("/forgot-password")}
              className="login-forgot-password-btn"
              type="button"
            >
              Forgot Password?
            </button>
          </div>

          {/* <button
            disabled={
              CompanyNameResponse &&
              (CompanyNameResponse?.type !== "failure" ||
                CompanyNameResponse?.isusable)
            }
            className="login-page-btn"
            type="submit"
          >
            Login
          </button> */}
          <div className="login_button">
            <CustomButton type="submit" btnType="primary" size="large">
              Login
            </CustomButton>
          </div>

          {/* <div className="login-page-para-reg-container">
            <p className="login-page-reg-para">Didn't have an account?</p>
            <a
              className="login-page-reg-para login-page-register-anchor"
              onClick={() => navigate("/register")}
              onKeyDown={() => {}}
            >
              Register
            </a>
          </div> */}
        </form>
        <div className="sideimage_container">
          <div className="grand_color"></div>
        </div>
      </div>
    </>
  );
};

export default LoginPage;
