import { Helmet } from "react-helmet-async";

export default function HelmetData({ title }) {
  const capitalizedA = title.charAt(0).toUpperCase() + title.slice(1);
  return (
    <Helmet>
      <title>{`${capitalizedA} -Rental`}</title>
    </Helmet>
  );
}
