import UsersTypes from "./UsersActionTypes";

//----------------team list GET--------------------
export const getUsersListRequest = (payload) => ({
  type: UsersTypes.GET_USERS_LIST_REQUEST,
  payload: payload,
});

export const getUsersListResponse = (payload) => ({
  type: UsersTypes.GET_USERS_LIST_RESPONSE,
  payload: payload,
});

export const getUsersByIdRequest = (id) => ({
  type: UsersTypes.GET_USERS_BY_ID_REQUEST,
  id: id,
});

export const getUsersByIdResponse = (payload) => ({
  type: UsersTypes.GET_USERS_BY_ID_RESPONSE,
  payload: payload,
});

export const getUsersByIdResponseClear = () => ({
  type: UsersTypes.GET_USERS_BY_ID_RESPONSE_CLEAR,
});
//----------------team POST--------------------------
export const postUsersRequest = (payload) => ({
  type: UsersTypes.POST_USERS_REQUEST,
  payload: payload,
});

export const postUsersResponse = (payload) => ({
  type: UsersTypes.POST_USERS_RESPONSE,
  payload: payload,
});

export const postUsersResponseClear = () => ({
  type: UsersTypes.POST_USERS_RESPONSE_CLEAR,
});

//----------------team PUT----------------------------
export const putUsersRequest = (payload, id) => ({
  type: UsersTypes.PUT_USERS_REQUEST,
  payload: payload,
  id: id,
});

export const putUsersResponse = (payload) => ({
  type: UsersTypes.PUT_USERS_RESPONSE,
  payload: payload,
});

export const putUsersResponseClear = () => ({
  type: UsersTypes.PUT_USERS_RESPONSE_CLEAR,
});

//--------------team DELETE----------------------------
export const deleteUsersRequest = (id) => ({
  type: UsersTypes.DELETE_USERS_REQUEST,
  id: id,
});

export const deleteUsersResponse = (payload) => ({
  type: UsersTypes.DELETE_USERS_RESPONSE,
  payload: payload,
});

export const deleteUsersResponseClear = () => ({
  type: UsersTypes.DELETE_USERS_RESPONSE_CLEAR,
});

//------- Put User ----
export const putUserRequest = (payload, id) => ({
  type: UsersTypes.PUT_USER_REQUEST,
  payload: payload,
  id: id,
});

export const putUserResponse = (payload) => ({
  type: UsersTypes.PUT_USER_RESPONSE,
  payload: payload,
});

export const putUserResponseClear = () => ({
  type: UsersTypes.PUT_USER_RESPONSE_CLEAR,
});
