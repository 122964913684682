import React, { useState } from "react";
import "./Layout.css";
import Sidenav from "../Sidenav/Sidenav";
import Header from "../Header/Header";
import { Outlet } from "react-router-dom";

const Layout = (props) => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  return (
    <>
      <div className={`Layout`}>
        <Sidenav isOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
        <div className={`Layout_main ${isSidebarOpen ? "shifted" : ""}`}>
          <div className="Layout_header">
            <Header
              toggleSidebar={toggleSidebar}
              isSidebarOpen={isSidebarOpen}
            />
          </div>
          <div className={`Layout_outlet ${isSidebarOpen ? "shifted" : ""}`}>
            <Outlet />
          </div>
        </div>
      </div>
    </>
  );
};

export default Layout;
