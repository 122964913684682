import { takeLatest, put, call } from "redux-saga/effects";
import CustomerTypes from "./CustomerActionTypes";
import {
  deleteCustomerResponse,
  getCustomerByIdResponse,
  getCustomerListResponse,
  postCustomerResponse,
  putCustomerResponse,
  putUserRequest,
  putUserResponse,
} from "./CustomerActions";
import { unAuthorizedData } from "../LoginSaga/LoginActions";
import { axiosDelete, axiosGet, axiosPost, axiosPut } from "../../Utils/axios";

export function* onGetCustomerList({ payload }) {
  try {
    const { filter, quickFilter = "", keyword = "", count = "" } = payload;

    const sort = filter.sortBy.split("-");

    const url = `customer?page=${
      filter.page
    }&filter=${keyword}&role=${quickFilter}&sort_by=${
      sort[0] ? sort[0] : ""
    }&sort=${sort[1] ? sort[1] : ""}&count=${count}`;

    const response = yield call(() => axiosGet(url).then((res) => res.data));
    yield put(getCustomerListResponse(response));
  } catch (error) {
    yield put(unAuthorizedData(error));
  }
}

export function* onGetByIdCustomer({ id }) {
  try {
    const url = `customer/${id}`;
    const response = yield call(() => axiosGet(url).then((res) => res.data));
    yield put(getCustomerByIdResponse(response));
  } catch (error) {
    yield put(unAuthorizedData(error));
  }
}

export function* onPostCustomer({ payload }) {
  try {
    const url = `customer`;
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };
    const response = yield call(() =>
      axiosPost(url, payload).then((res) => res.data)
    );
    yield put(postCustomerResponse(response));
  } catch (error) {
    yield put(unAuthorizedData(error));
  }
}

export function* onPutCustomer({ payload, id }) {
  try {
    const url = `customer/${id}`;
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };
    const response = yield call(() =>
      axiosPut(url, payload).then((res) => res.data)
    );
    yield put(putCustomerResponse(response));
  } catch (error) {
    yield put(unAuthorizedData(error));
  }
}

export function* onDeleteCustomer({ id }) {
  try {
    const url = `customer/${id}`;

    const response = yield call(() => axiosDelete(url).then((res) => res.data));
    yield put(deleteCustomerResponse(response));
  } catch (error) {
    yield put(unAuthorizedData(error));
  }
}
export function* onPutUser({ payload, id }) {
  try {
    const url = `admin/${id}`;
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };
    const response = yield call(() =>
      axiosPut(url, payload, config).then((res) => res.data)
    );
    yield put(putUserResponse(response));
  } catch (error) {
    yield put(unAuthorizedData(error));
  }
}

export function* CustomerWatcherSaga() {
  yield takeLatest(CustomerTypes.GET_CUSTOMER_LIST_REQUEST, onGetCustomerList);
  yield takeLatest(CustomerTypes.GET_CUSTOMER_BY_ID_REQUEST, onGetByIdCustomer);
  yield takeLatest(CustomerTypes.POST_CUSTOMER_REQUEST, onPostCustomer);
  yield takeLatest(CustomerTypes.PUT_CUSTOMER_REQUEST, onPutCustomer);
  yield takeLatest(CustomerTypes.PUT_USER_REQUEST, onPutUser);
  yield takeLatest(CustomerTypes.DELETE_CUSTOMER_REQUEST, onDeleteCustomer);
}
