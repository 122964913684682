import {
  Country as country_api,
  State as state_api,
  City as city_api,
} from "country-state-city";
import BusinessIcon from "@mui/icons-material/Business";
import RecentActorsIcon from "@mui/icons-material/RecentActors";
import ImportExportIcon from "@mui/icons-material/ImportExport";
import PeopleIcon from "@mui/icons-material/People";
import TimelapseIcon from "@mui/icons-material/Timelapse";
import ReceiptIcon from "@mui/icons-material/Receipt";
import NotificationsIcon from "@mui/icons-material/Notifications";
import ImportantDevicesIcon from "@mui/icons-material/ImportantDevices";
import PlaylistAddCheckIcon from "@mui/icons-material/PlaylistAddCheck";
import DateRangeIcon from "@mui/icons-material/DateRange";
import MarkEmailReadIcon from "@mui/icons-material/MarkEmailRead";
import LinearScaleIcon from "@mui/icons-material/LinearScale";
import CompareArrowsIcon from "@mui/icons-material/CompareArrows";
import ContentPasteIcon from "@mui/icons-material/ContentPaste";
import PersonIcon from "@mui/icons-material/Person";
import WorkIcon from "@mui/icons-material/Work";
import PermDataSettingIcon from "@mui/icons-material/PermDataSetting";


export const countryList = country_api.getAllCountries();

export const stateList = state_api;

export const cityList = city_api;
export const imageFileFormat = ["jpeg", "jpg", "png", "gif"];

export const statusList = [
  { name: "Active", value: true },
  { name: "Inactive", value: false },
];

export const employeeSortList = [
  // { name: "sort filter", value: "sort filter", disabled: true },
  { name: "Created Date-ASC", value: "createdAt-ASC" },
  { name: "Created Date-DSC", value: "createdAt-DSC" },
  { name: "Name-ASC", value: "first_name-ASC" },
  { name: "Name-DSC", value: "first_name-DSC" },
  { name: "Employee code-ASC", value: "employeecode-ASC" },
  { name: "Employee code-DSC", value: "employeecode-DSC" },
];

export const customerSortList = [
  // { name: "sort filter", value: "sort filter", disabled: true },
  { name: "Created Date-ASC", value: "createdAt-ASC" },
  { name: "Created Date-DSC", value: "createdAt-DSC" },
  { name: "Name-ASC", value: "name-ASC" },
  { name: "Name-DSC", value: "name-DSC" },
];
export const TimeSheetSortList = [
  // { name: "sort filter", value: "sort filter", disabled: true },
  { name: "Created Date-ASC", value: "createdAt-ASC" },
  { name: "Created Date-DSC", value: "createdAt-DSC" },
];
export const sortList = [
  // { name: "sort filter", value: "sort filter", disabled: true },
  { name: "Created Date-ASC", value: "createdAt-ASC" },
  { name: "Created Date-DSC", value: "createdAt-DSC" },
  { name: "Name-ASC", value: "name-ASC" },
  { name: "Name-DSC", value: "name-DSC" },
];

export const jobSortList = [
  // { name: "sort filter", value: "sort filter", disabled: true },
  { name: "Created Date-ASC", value: "createdAt-ASC" },
  { name: "Created Date-DSC", value: "createdAt-DSC" },
  { name: "Name-ASC", value: "title-ASC" },
  { name: "Name-DSC", value: "title-DSC" },
];

export const productSortList = [
  // { name: "sort filter", value: "sort filter", disabled: true },
  { name: "Created Date-ASC", value: "createdAt-ASC" },
  { name: "Created Date-DSC", value: "createdAt-DSC" },
  { name: "Name-ASC", value: "ProductName-ASC" },
  { name: "Name-DSC", value: "ProductName-DSC" },
];

export const masterShiftSortList = [
  // { name: "sort filter", value: "sort filter", disabled: true },
  { name: "Created Date-ASC", value: "createdAt-ASC" },
  { name: "Created Date-DSC", value: "createdAt-DSC" },
  { name: "Name-ASC", value: "shift_name-ASC" },
  { name: "Name-DSC", value: "shift_name-DSC" },
];

export const taxSortList = [
  // { name: "sort filter", value: "sort filter", disabled: true },
  { name: "Created Date-ASC", value: "createdAt-ASC" },
  { name: "Created Date-DSC", value: "createdAt-DSC" },
  { name: "Name-ASC", value: "tax_name-ASC" },
  { name: "Name-DSC", value: "tax_name-DSC" },
];

export const taxGroupSortList = [
  // { name: "sort filter", value: "sort filter", disabled: true },
  { name: "Created Date-ASC", value: "createdAt-ASC" },
  { name: "Created Date-DSC", value: "createdAt-DSC" },
  { name: "Name-ASC", value: "group_name-ASC" },
  { name: "Name-DSC", value: "group_name-DSC" },
];

export const templateSortList = [
  // { name: "sort filter", value: "sort filter", disabled: true },
  { name: "Created Date-ASC", value: "createdAt-ASC" },
  { name: "Created Date-DSC", value: "createdAt-DSC" },
  { name: "Name-ASC", value: "template_name-ASC" },
  { name: "Name-DSC", value: "template_name-DSC" },
];

export const followUpSortList = [
  { name: "Created Date-ASC", value: "createdAt-ASC" },
  { name: "Created Date-DSC", value: "createdAt-DSC" },
  { name: "Name-ASC", value: "module-ASC" },
  { name: "Name-DSC", value: "module-DSC" },
];

export const holidaySortList = [
  { name: "Created Date-ASC", value: "createdAt-ASC" },
  { name: "Created Date-DSC", value: "createdAt-DSC" },
  { name: "Name-ASC", value: "holiday_name-ASC" },
  { name: "Name-DSC", value: "holiday_name-DSC" },
];

export const invoiceSortList = [
  { name: "Created Date-ASC", value: "createdAt-ASC" },
  { name: "Created Date-DSC", value: "createdAt-DSC" },
  { name: "Invoice Date-ASC", value: "invoice_date-ASC" },
  { name: "Invoice Date-DSC", value: "invoice_date-DSC" },
  { name: "Due Date-ASC", value: "due_date-ASC" },
  { name: "Due Date-DSC", value: "due_date-DSC" },
];

export const hoursList = [
  { name: "00", value: "00" },
  { name: "01", value: "01" },
  { name: "02", value: "02" },
  { name: "03", value: "03" },
  { name: "04", value: "04" },
  { name: "05", value: "05" },
  { name: "06", value: "06" },
  { name: "07", value: "07" },
  { name: "08", value: "08" },
  { name: "09", value: "09" },
  { name: "10", value: "10" },
  { name: "11", value: "11" },
  { name: "12", value: "12" },
  { name: "13", value: "13" },
  { name: "14", value: "14" },
  { name: "15", value: "15" },
  { name: "16", value: "16" },
  { name: "17", value: "17" },
  { name: "18", value: "18" },
  { name: "19", value: "19" },
  { name: "20", value: "20" },
  { name: "21", value: "21" },
  { name: "22", value: "22" },
  { name: "23", value: "23" },
];

export const minutesList = [
  { name: "00", value: "00" },
  { name: "01", value: "01" },
  { name: "02", value: "02" },
  { name: "03", value: "03" },
  { name: "04", value: "04" },
  { name: "05", value: "05" },
  { name: "06", value: "06" },
  { name: "07", value: "07" },
  { name: "08", value: "08" },
  { name: "09", value: "09" },
  { name: "10", value: "10" },
  { name: "11", value: "11" },
  { name: "12", value: "12" },
  { name: "13", value: "13" },
  { name: "14", value: "14" },
  { name: "15", value: "15" },
  { name: "16", value: "16" },
  { name: "17", value: "17" },
  { name: "18", value: "18" },
  { name: "19", value: "19" },
  { name: "20", value: "20" },
  { name: "21", value: "21" },
  { name: "22", value: "22" },
  { name: "23", value: "23" },
  { name: "24", value: "24" },
  { name: "25", value: "25" },
  { name: "26", value: "26" },
  { name: "27", value: "27" },
  { name: "28", value: "28" },
  { name: "29", value: "29" },
  { name: "30", value: "30" },
  { name: "31", value: "31" },
  { name: "32", value: "32" },
  { name: "33", value: "33" },
  { name: "34", value: "34" },
  { name: "35", value: "35" },
  { name: "36", value: "36" },
  { name: "37", value: "37" },
  { name: "38", value: "38" },
  { name: "39", value: "39" },
  { name: "40", value: "40" },
  { name: "41", value: "41" },
  { name: "42", value: "42" },
  { name: "43", value: "43" },
  { name: "44", value: "44" },
  { name: "45", value: "45" },
  { name: "46", value: "46" },
  { name: "47", value: "47" },
  { name: "48", value: "48" },
  { name: "49", value: "49" },
  { name: "50", value: "50" },
  { name: "51", value: "51" },
  { name: "52", value: "52" },
  { name: "53", value: "53" },
  { name: "54", value: "54" },
  { name: "55", value: "55" },
  { name: "56", value: "56" },
  { name: "57", value: "57" },
  { name: "58", value: "58" },
  { name: "59", value: "59" },
];

export const jobStatusDropdownList = [
  { name: "Yes", value: "true" },
  { name: "No", value: "false" },
];

export const statusType = [
  { name: "NEW", value: "NEW" },
  { name: "SCHEDULED", value: "SCHEDULED" },
  { name: "ON_MY_WAY", value: "ON_MY_WAY" },
  { name: "STARTED", value: "STARTED" },
  { name: "ON_HOLD", value: "ON_HOLD" },
  { name: "COMPLETED", value: "COMPLETED" },
  { name: "CANNOT_COMPLETE", value: "CANNOT_COMPLETE" },
  { name: "CLOSED", value: "CLOSED" },
  { name: "FAILED", value: "FAILED" },
  { name: "PAID", value: "PAID" },
  { name: "FOLLOW_UP", value: "FOLLOW_UP" },
  { name: "OTHER", value: "OTHER" },
  { name: "FOLLOW_UP_SAME_JOB", value: "FOLLOW_UP_SAME_JOB" },
  { name: "REQUEST_INITIATED", value: "REQUEST_INITIATED" },
  { name: "REQUEST_APPROVED", value: "REQUEST_APPROVED" },
  { name: "REQUEST_REJECTED", value: "REQUEST_REJECTED" },
  { name: "INVOICE", value: "INVOICE" },
];

export const timeZoneList =  [
  { name: "(GMT -12:00) Eniwetok, Kwajalein", value: "Etc/GMT+12"},
  { name: "(GMT -11:00) Midway Island, Samoa", value: "Etc/GMT+11"},
  { name: "(GMT -10:00) Hawaii", value: "Etc/GMT+10"},
  { name: "(GMT -9:30) Taiohae", value: "Pacific/Marquesas"},
  { name: "(GMT -9:00) Alaska", value: "Etc/GMT+9"},
  { name: "(GMT -8:00) Pacific Time (US &amp; Canada)", value: "Etc/GMT+8"},
  { name: "(GMT -7:00) Mountain Time (US &amp; Canada)", value: "Etc/GMT+7"},
  { name: "(GMT -6:00) Central Time (US &amp; Canada), Mexico City", value: "Etc/GMT+6"},
  { name: "(GMT -5:00) Eastern Time (US &amp; Canada), Bogota, Lima", value: "Etc/GMT+5"},
  // { name: "(GMT -4:30) Caracas", value: "-04:50"},
  { name: "(GMT -4:00) Atlantic Time (Canada), Caracas, La Paz", value: "Etc/GMT+4"},
  // { name: "(GMT -3:30) Newfoundland", value: "-03:50"},
  { name: "(GMT -3:00) Brazil, Buenos Aires, Georgetown", value: "Etc/GMT+3"},
  { name: "(GMT -2:00) Mid-Atlantic", value: "Etc/GMT+2"},
  { name: "(GMT -1:00) Azores, Cape Verde Islands", value: "Etc/GMT+1"},
  { name: "(GMT) Western Europe Time, London, Lisbon, Casablanca", value: "Etc/GMT"},
  { name: "(GMT +1:00) Brussels, Copenhagen, Madrid, Paris", value: "Etc/GMT-1"},
  { name: "(GMT +2:00) Kaliningrad, South Africa", value: "Etc/GMT-2"},
  { name: "(GMT +3:00) Baghdad, Riyadh, Moscow, St. Petersburg", value: "Etc/GMT-3"},
  { name: "(GMT +3:30) Tehran", value: "Asia/Tehran"},
  { name: "(GMT +4:00) Abu Dhabi, Muscat, Baku, Tbilisi", value: "Etc/GMT-4"},
  { name: "(GMT +4:30) Kabul", value: "Asia/Kabul"},
  { name: "(GMT +5:00) Ekaterinburg, Islamabad, Karachi, Tashkent", value: "Etc/GMT-5"},
  { name: "(GMT +5:30) Bombay, Calcutta, Madras, New Delhi", value: "Asia/Kolkata"},
  { name: "(GMT +5:45) Kathmandu, Pokhara", value: "Asia/Katmandu"},
  { name: "(GMT +6:00) Almaty, Dhaka, Colombo", value: "Etc/GMT-6"},
  { name: "(GMT +6:30) Yangon, Mandalay", value: "Asia/Rangoon"},
  { name: "(GMT +7:00) Bangkok, Hanoi, Jakarta", value: "Etc/GMT-7"},
  { name: "(GMT +8:00) Beijing, Perth, Singapore, Hong Kong", value: "Etc/GMT-8"},
  { name: "(GMT +8:45) Eucla", value: "Australia/Eucla"},
  { name: "(GMT +9:00) Tokyo, Seoul, Osaka, Sapporo, Yakutsk", value: "Etc/GMT-9"},
  { name: "(GMT +9:30) Adelaide, Darwin", value: "Australia/North"},
  { name: "(GMT +10:00) Eastern Australia, Guam, Vladivostok", value: "Etc/GMT-10"},
  // { name: "(GMT +10:30) Lord Howe Island", value: "+10:50"},
  { name: "(GMT +11:00) Magadan, Solomon Islands, New Caledonia", value: "Etc/GMT-11"},
  // { name: "(GMT +11:30) Norfolk Island", value: "+11:50"},
  { name: "(GMT +12:00) Auckland, Wellington, Fiji, Kamchatka", value: "Etc/GMT-12"},
  // { name: "(GMT +12:45) Chatham Islands", value: "+12:75"},
  { name: "(GMT +13:00) Apia, Nukualofa", value: "Etc/GMT-13"},
  { name: "(GMT +14:00) Line Islands, Tokelau", value: "Etc/GMT-14"}
]


export const languageList = 
[
{value:"AF", name: "Afrikaans"},
  {value:"SQ", name: "Albanian"},
  {value:"AR", name: "Arabic"},
  {value:"HY", name: "Armenian"},
  {value:"EU", name: "Basque"},
  {value:"BN", name: "Bengali"},
  {value:"BG", name: "Bulgarian"},
  {value:"CA", name: "Catalan"},
  {value:"KM", name: "Cambodian"},
  {value:"ZH", name: "Chinese (Mandarin)"},
  {value:"HR", name: "Croatian"},
  {value:"CS", name: "Czech"},
  {value:"DA", name: "Danish"},
  {value:"NL", name: "Dutch"},
  {value:"EN", name: "English"},
  {value:"ET", name: "Estonian"},
  {value:"FJ", name: "Fiji"},
  {value:"FI", name: "Finnish"},
  {value:"FR", name: "French"},
  {value:"KA", name: "Georgian"},
  {value:"DE", name: "German"},
  {value:"EL", name: "Greek"},
  {value:"GU", name: "Gujarati"},
  {value:"HE", name: "Hebrew"},
  {value:"HI", name: "Hindi"},
  {value:"HU", name: "Hungarian"},
  {value:"IS", name: "Icelandic"},
  {value:"ID", name: "Indonesian"},
  {value:"GA", name: "Irish"},
  {value:"IT", name: "Italian"},
  {value:"JA", name: "Japanese"},
  {value:"JW", name: "Javanese"},
  {value:"KO", name: "Korean"},
  {value:"LA", name: "Latin"},
  {value:"LV", name: "Latvian"},
  {value:"LT", name: "Lithuanian"},
  {value:"MK", name: "Macedonian"},
  {value:"MS", name: "Malay"},
  {value:"ML", name: "Malayalam"},
  {value:"MT", name: "Maltese"},
  {value:"MI", name: "Maori"},
  {value:"MR", name: "Marathi"},
  {value:"MN", name: "Mongolian"},
  {value:"NE", name: "Nepali"},
  {value:"NO", name: "Norwegian"},
  {value:"FA", name: "Persian"},
  {value:"PL", name: "Polish"},
  {value:"PT", name: "Portuguese"},
  {value:"PA", name: "Punjabi"},
  {value:"QU", name: "Quechua"},
  {value:"RO", name: "Romanian"},
  {value:"RU", name: "Russian"},
  {value:"SM", name: "Samoan"},
  {value:"SR", name: "Serbian"},
  {value:"SK", name: "Slovak"},
  {value:"SL", name: "Slovenian"},
  {value:"ES", name: "Spanish"},
  {value:"SW", name: "Swahili"},
  {value:"SV", name: "Swedish" },
  {value:"TA", name: "Tamil"},
  {value:"TT", name: "Tatar"},
  {value:"TE", name: "Telugu"},
  {value:"TH", name: "Thai"},
  {value:"BO", name: "Tibetan"},
  {value:"TO", name: "Tonga"},
  {value:"TR", name: "Turkish"},
  {value:"UK", name: "Ukrainian"},
  {value:"UR", name: "Urdu"},
  {value:"UZ", name: "Uzbek"},
  {value:"VI", name: "Vietnamese"},
  {value:"CY", name: "Welsh"},
  {value:"XH", name: "Xhosa"}]

export const timeFormatList = [
  {value: '12', name: "12 Hrs Format"},
  {value: '24', name: "24 Hrs Format"}
]

export const TableIconConstant = {
  view: "view",
  edit: "edit",
  addUser: "addUser",
  status: "status",
  time: "time",
  delete: "delete",
  copy: "copy",
  barcode: "barcode",
  qrcode: "qrcode",
  location: "location",
  close: "close",
  history: "history",
  add: "add",
  inventory: "inventory",
  remove: "remove",
};

export const customSettingsImgList = {
  "Customer Fields": <PeopleIcon />,
  "Employee Fields": <PeopleIcon />,
  "Asset Fields": <ImportantDevicesIcon />,
  "Organization Fields": <BusinessIcon />,
  "Quotation Fields": <ReceiptIcon />,
  "Job Checklist": <PlaylistAddCheckIcon />,
};
export const iconslist = [
  <PersonIcon />,
  <RecentActorsIcon />,
  <TimelapseIcon />,
  <PeopleIcon />,
  <ImportantDevicesIcon />,
  <ReceiptIcon />,
  <BusinessIcon />,
  <PlaylistAddCheckIcon />,
  <LinearScaleIcon />,
  <NotificationsIcon />,
];
export const settingsList = [
  {
    title: "GENERAL SETTINGS",
    data: [
      {
        img: <PersonIcon />,
        name: "Account Settings",
        description: "Update your profile",
        url: "generalsettings",
      },
      {
        img: <BusinessIcon />,
        name: "Organization Settings",
        description: "Update company configs",
        url: "/comingsoon",
      },
      {
        img: <RecentActorsIcon />,
        name: "Custom Roles",
        description: "Roles & Permissions",
        url: "custom-role",
      },
      {
        img: <ImportExportIcon />,
        name: "Data Import",
        description: "Import your existing data",
        url: "/comingsoon",
      },
    ],
  },
  {
    title: "CONFIGURATION SETTINGS",
    data: [
      {
        img: <PeopleIcon />,
        name: "Customer Settings",
        description: "Manage customer",
        url: "customer-settings",
      },
      {
        img: <TimelapseIcon />,
        name: "Timesheet Settings",
        description: "Manage timesheet configs",
        url: "timesheet_settings",
      },
      {
        img: <ReceiptIcon />,
        name: "Quotes & Invoices",
        description: "Manage payments, taxes, etc..,",
        url: "quoteandinvoice-setting",
      },
      {
        img: <ContentPasteIcon />,
        name: "Contracts",
        description: "Manage packages, billings, etc.,",
        url: "/comingsoon",
      },
      {
        img: <ImportantDevicesIcon />,
        name: "Assets",
        description: "Manage asset configs",
        url: "/comingsoon",
      },
      {
        img: <NotificationsIcon />,
        name: "Notification & Alerts",
        description: "Manage notification configs",
        url: "notificationsettings",
      },
      {
        img: <PermDataSettingIcon />,
        name: "Products",
        description: "Manage parts & services",
        url: "partandservice_settings",
      },
      {
        img: <WorkIcon />,
        name: "Job Settings",
        description: "Manage job settings",
        url: "job_settings",
      },
      {
        img: <TimelapseIcon />,
        name: "Custom Function Settings",
        description: "Manage Custom Function settings",
        url: "custom_function_settings",
      },
    ],
  },
  {
    title: "CUSTOM FIELDS SETTINGS",
    data: [
      {
        img: <PeopleIcon />,
        name: "Customer Fields",
        description: "Manage customer",
        url: "/comingsoon",
      },
      {
        img: <PeopleIcon />,
        name: "Employee Fields",
        description: "Manage timesheet configs",
        url: "employeefield",
      },
      {
        img: <ImportantDevicesIcon />,
        name: "Asset Fields",
        description: "Manage payments, taxes, etc..,",
        url: "/comingsoon",
      },
      {
        img: <BusinessIcon />,
        name: "Organization Fields",
        description: "Manage packages, billings, etc.,",
        url: "/comingsoon",
      },

      {
        img: <ReceiptIcon />,
        name: "Quotation Fields",
        description: "Manage asset configs",
        url: "/comingsoon",
      },
      {
        img: <PlaylistAddCheckIcon />,
        name: "Job Checklist",
        description: "Manage notification configs",
        url: "/comingsoon",
      },
    ],
  },
  {
    title: "OTHER SETTINGS",
    data: [
      {
        img: <DateRangeIcon />,
        name: "Holidays",
        description: "Manage holidays",
        url: "holiday",
      },
      {
        img: <MarkEmailReadIcon />,
        name: "Email Templates",
        description: "Manage email templates",
        url: "/comingsoon",
      },
      {
        img: <LinearScaleIcon />,
        name: "Workflows",
        description: "Manage predefined actions",
        url: "/setting/workflows",
      },
      {
        img: <CompareArrowsIcon />,
        name: "Webhooks",
        description: "Manage webhooks",
        url: "/comingsoon",
      },
    ],
  },
];
export const ReportList = [
  {
    title: "CUSTOM REPORT",
    data: [
      {
        name: "Custom Report One",
        url: "/comingsoon",
      },
      {
        name: "Custom Report One",
        url: "/comingsoon",
      },
      {
        name: "Custom Report One",
        url: "/comingsoon",
      },
      {
        name: "Custom Report One",
        url: "/comingsoon",
      },
      {
        name: "Custom Report One",
        url: "/comingsoon",
      },
    ],
  },
  {
    title: "JOB REPORT",
    data: [
      {
        name: "Custom Report One",
        url: "/comingsoon",
      },
      {
        name: "Custom Report One",
        url: "/comingsoon",
      },
      {
        name: "Custom Report One",
        url: "/comingsoon",
      },
      {
        name: "Custom Report One",
        url: "/comingsoon",
      },
      {
        name: "Custom Report One",
        url: "/comingsoon",
      },
      {
        name: "Custom Report One",
        url: "/comingsoon",
      },

      {
        name: "Custom Report One",
        url: "/comingsoon",
      },
      {
        name: "Custom Report One",
        url: "/comingsoon",
      },
      {
        name: "Custom Report One",
        url: "/comingsoon",
      },
      {
        name: "Custom Report One",
        url: "/comingsoon",
      },
      {
        name: "Custom Report One",
        url: "/comingsoon",
      },
      {
        name: "Custom Report One",
        url: "/comingsoon",
      },

      {
        name: "Custom Report One",
        url: "/comingsoon",
      },
      {
        name: "Custom Report One",
        url: "/comingsoon",
      },
      {
        name: "Custom Report One",
        url: "/comingsoon",
      },
      {
        name: "Custom Report One",
        url: "/comingsoon",
      },
    ],
  },
  {
    title: "QUOTATION & INVOICE REPORTS",
    data: [
      {
        name: "Custom Report One",
        url: "/comingsoon",
      },
      {
        name: "Custom Report One",
        url: "/comingsoon",
      },
      {
        name: "Custom Report One",
        url: "/comingsoon",
      },
      {
        name: "Custom Report One",
        url: "/comingsoon",
      },
      {
        name: "Custom Report One",
        url: "/comingsoon",
      },
      {
        name: "Custom Report One",
        url: "/comingsoon",
      },

      {
        name: "Custom Report One",
        url: "/comingsoon",
      },
      {
        name: "Custom Report One",
        url: "/comingsoon",
      },
      {
        name: "Custom Report One",
        url: "/comingsoon",
      },
      {
        name: "Custom Report One",
        url: "/comingsoon",
      },
      {
        name: "Custom Report One",
        url: "/comingsoon",
      },
      {
        name: "Custom Report One",
        url: "/comingsoon",
      },
      {
        name: "Custom Report One",
        url: "/comingsoon",
      },
      {
        name: "Custom Report One",
        url: "/comingsoon",
      },

      {
        name: "Custom Report One",
        url: "/comingsoon",
      },
      {
        name: "Custom Report One",
        url: "/comingsoon",
      },
    ],
  },
];

export const customRolesList = [
  {
    role: "Manager",
    description: "Lorem ipsum",
    created_by: "Luther King",
    created_at: "2019/10/23 02:46",
    status: "inactive",
  },
  {
    role: "Sr. Manager",
    description: "Lorem ipsum",
    created_by: "Luther King",
    created_at: "2019/10/23 02:54",
    status: "Active",
  },
  {
    role: "Team Member",
    description: "Lorem ipsum",
    created_by: "Luther King",
    created_at: "2019/10/23 03:46",
    status: "Active",
  },
  {
    role: "Admin",
    description: "Lorem ipsum",
    created_by: "Luther King",
    created_at: "2019/10/23 04:46",
    status: "Active",
  },
];

export const employeesList = [
  {
    employee_code: 12345,
    name: "Luther King",
    designation: "CEO",
    role: "Admin",
    phone_number: "9876543210",
    created_on: "2020/10/20 02:22",
    status: "Inactive",
  },
  {
    employee_code: 12346,
    name: "Luther King",
    designation: "COO",
    role: "Admin",
    phone_number: "9876543210",
    created_on: "2020/10/20 02:22",
    status: "Active",
  },
  {
    employee_code: 12347,
    name: "Luther King",
    designation: "UI UX Designer",
    role: "Team Lead",
    phone_number: "9876543210",
    created_on: "2020/10/20 02:22",
    status: "Active",
  },
  {
    employee_code: 12348,
    name: "Luther King",
    designation: "Front End Developer",
    role: "Team Member",
    phone_number: "9876543210",
    created_on: "2020/10/20 02:22",
    status: "Active",
  },
];

export const Timeformat = [
  { name: "24 Hrs Format" },
  { name: "12 Hrs Format" },
];

export const expression = {
  email: /^([a-zA-Z0-9_+\-\.]+)@([a-zA-Z\.]+)\.([a-zA-Z]{2,5})$/,
  space:
    /^[^\s].+([A-Za-z0-9!+@#$%^&*()_,/.-]+\s)*[A-Za-z0-9!+@#$%^&_(),/.-]+$/,
  Without_space: /^(?!\s).+(?<!\s)$/,
  space_message: "should not contain spaces",
  min_one_char: /^(\w| )*[A-Za-z](\w| )*$/,
  zipcode: /^[0-9-]*$/,
  char: /^[A-Za-z\s]+$/,
  percentage: /\b(?<!\.)(?!0+(?:\.0+)?%)(?:\d|[1-9]\d|100)(?:(?<!100)\.\d+)?%/,
};
export const currency = [
  { name: "USD" },
  { name: "CAD" },
  { name: "EUR" },
  { name: "AED" },
  { name: "AFN" },
  { name: "ALL" },
  { name: "AMD" },
  { name: "ARS" },
  { name: "AUD" },
  { name: "AZN" },
  { name: "BAM" },
  { name: "BDT" },
  { name: "BGN" },
  { name: "BHD" },
  { name: "BIF" },
  { name: "BND" },
  { name: "BOB" },
  { name: "BRL" },
  { name: "BWP" },
  { name: "BYN" },
  { name: "BZD" },
  { name: "CDF" },
  { name: "CHF" },
  { name: "CLP" },
  { name: "CNY" },
  { name: "COP" },
  { name: "CRC" },
  { name: "CVE" },
  { name: "CZK" },
  { name: "DJF" },
  { name: "DKK" },
  { name: "DOP" },
  { name: "DZD" },
  { name: "EEK" },
  { name: "EGP" },
  { name: "ERN" },
  { name: "ETB" },
  { name: "GBP" },
  { name: "GEL" },
  { name: "GHS" },
  { name: "GNF" },
  { name: "GTQ" },
  { name: "HKD" },
  { name: "HNL" },
  { name: "HRK" },
  { name: "HUF" },
  { name: "IDR" },
  { name: "ILS" },
  { name: "INR" },
  { name: "IQD" },
  { name: "IRR" },
  { name: "ISK" },
  { name: "JMD" },
  { name: "JOD" },
  { name: "JPY" },
  { name: "KES" },
  { name: "KHR" },
  { name: "KMF" },
  { name: "KRW" },
  { name: "KWD" },
  { name: "KZT" },
  { name: "LBP" },
  { name: "LKR" },
  { name: "LTL" },
  { name: "LVL" },
  { name: "LYD" },
  { name: "MAD" },
  { name: "MDL" },
  { name: "MGA" },
  { name: "MKD" },
  { name: "MMK" },
  { name: "MOP" },
  { name: "MUR" },
  { name: "MXN" },
  { name: "MYR" },
  { name: "MZN" },
  { name: "NAD" },
  { name: "NGN" },
  { name: "NIO" },
  { name: "NOK" },
  { name: "NPR" },
  { name: "NZD" },
  { name: "OMR" },
  { name: "PAB" },
  { name: "PEN" },
  { name: "PHP" },
  { name: "PKR" },
  { name: "PLN" },
  { name: "PYG" },
  { name: "QAR" },
  { name: "RON" },
  { name: "RSD" },
  { name: "RUB" },
  { name: "RWF" },
  { name: "SAR" },
  { name: "SDG" },
  { name: "SEK" },
  { name: "SGD" },
  { name: "SOS" },
  { name: "SYP" },
  { name: "THB" },
  { name: "TND" },
  { name: "TOP" },
  { name: "TRY" },
  { name: "TTD" },
  { name: "TWD" },
  { name: "TZS" },
  { name: "UAH" },
  { name: "UGX" },
  { name: "UYU" },
  { name: "UZS" },
  { name: "VEF" },
  { name: "VND" },
  { name: "XAF" },
  { name: "XOF" },
  { name: "YER" },
  { name: "ZAR" },
  { name: "ZMK" },
  { name: "ZWL" },
];

export const lengthUnit = [
  { name: "milli meter", value: "mm" },
  { name: "centimeter", value: "cm" },
  { name: "inch", value: "in" },
  { name: "metre", value: "m" },
  { name: "foot", value: "ft" },
];

export const weightUnit = [
  { name: "grams", value: "g" },
  { name: "kilogram", value: "kg" },
  { name: "pound", value: "lb" },
  { name: "ounces", value: "oz" },
  { name: "Gallon", value: "gal" },
  { name: "milli litres", value: "ml" },
  { name: "litres", value: "l" },
];

export const Languages = [
  { code: "ab", name: "Abkhazian" },
  { code: "aa", name: "Afar" },
  { code: "af", name: "Afrikaans" },
  { code: "ak", name: "Akan" },
  { code: "sq", name: "Albanian" },
  { code: "am", name: "Amharic" },
  { code: "ar", name: "Arabic" },
  { code: "an", name: "Aragonese" },
  { code: "hy", name: "Armenian" },
  { code: "as", name: "Assamese" },
  { code: "av", name: "Avaric" },
  { code: "ae", name: "Avestan" },
  { code: "ay", name: "Aymara" },
  { code: "az", name: "Azerbaijani" },
  { code: "bm", name: "Bambara" },
  { code: "ba", name: "Bashkir" },
  { code: "eu", name: "Basque" },
  { code: "be", name: "Belarusian" },
  { code: "bn", name: "Bengali" },
  { code: "bh", name: "Bihari languages" },
  { code: "bi", name: "Bislama" },
  { code: "bs", name: "Bosnian" },
  { code: "br", name: "Breton" },
  { code: "bg", name: "Bulgarian" },
  { code: "my", name: "Burmese" },
  { code: "ca", name: "Catalan, Valcodeian" },
  { code: "km", name: "Central Khmer" },
  { code: "ch", name: "Chamorro" },
  { code: "ce", name: "Chechen" },
  { code: "ny", name: "Chichewa, Chewa, Nyanja" },
  { code: "zh", name: "Chinese" },
  { code: "cu", name: "Church Slavonic, Old Bulgarian, Old Church Slavonic" },
  { code: "cv", name: "Chuvash" },
  { code: "kw", name: "Cornish" },
  { code: "co", name: "Corsican" },
  { code: "cr", name: "Cree" },
  { code: "hr", name: "Croatian" },
  { code: "cs", name: "Czech" },
  { code: "da", name: "Danish" },
  { code: "dv", name: "Divehi, Dhivehi, Maldivian" },
  { code: "nl", name: "Dutch, Flemish" },
  { code: "dz", name: "Dzongkha" },
  { code: "en", name: "English" },
  { code: "eo", name: "Esperanto" },
  { code: "et", name: "Estonian" },
  { code: "ee", name: "Ewe" },
  { code: "fo", name: "Faroese" },
  { code: "fj", name: "Fijian" },
  { code: "fi", name: "Finnish" },
  { code: "fr", name: "Frcodeh" },
  { code: "ff", name: "Fulah" },
  { code: "gd", name: "Gaelic, Scottish Gaelic" },
  { code: "gl", name: "Galician" },
  { code: "lg", name: "Ganda" },
  { code: "ka", name: "Georgian" },
  { code: "de", name: "German" },
  { code: "ki", name: "Gikuyu, Kikuyu" },
  { code: "el", name: "Greek (Modern)" },
  { code: "kl", name: "Greenlandic, Kalaallisut" },
  { code: "gn", name: "Guarani" },
  { code: "gu", name: "Gujarati" },
  { code: "ht", name: "Haitian, Haitian Creole" },
  { code: "ha", name: "Hausa" },
  { code: "he", name: "Hebrew" },
  { code: "hz", name: "Herero" },
  { code: "hi", name: "Hindi" },
  { code: "ho", name: "Hiri Motu" },
  { code: "hu", name: "Hungarian" },
  { code: "is", name: "Icelandic" },
  { code: "io", name: "Ido" },
  { code: "ig", name: "Igbo" },
  { code: "id", name: "Indonesian" },
  {
    code: "ia",
    name: "Interlingua (International Auxiliary Language Association)",
  },
  { code: "ie", name: "Interlingue" },
  { code: "iu", name: "Inuktitut" },
  { code: "ik", name: "Inupiaq" },
  { code: "ga", name: "Irish" },
  { code: "it", name: "Italian" },
  { code: "ja", name: "Japanese" },
  { code: "jv", name: "Javanese" },
  { code: "kn", name: "Kannada" },
  { code: "kr", name: "Kanuri" },
  { code: "ks", name: "Kashmiri" },
  { code: "kk", name: "Kazakh" },
  { code: "rw", name: "Kinyarwanda" },
  { code: "kv", name: "Komi" },
  { code: "kg", name: "Kongo" },
  { code: "ko", name: "Korean" },
  { code: "kj", name: "Kwanyama, Kuanyama" },
  { code: "ku", name: "Kurdish" },
  { code: "ky", name: "Kyrgyz" },
  { code: "lo", name: "Lao" },
  { code: "la", name: "Latin" },
  { code: "lv", name: "Latvian" },
  { code: "lb", name: "Letzeburgesch, Luxembourgish" },
  { code: "li", name: "Limburgish, Limburgan, Limburger" },
  { code: "ln", name: "Lingala" },
  { code: "lt", name: "Lithuanian" },
  { code: "lu", name: "Luba-Katanga" },
  { code: "mk", name: "Macedonian" },
  { code: "mg", name: "Malagasy" },
  { code: "ms", name: "Malay" },
  { code: "ml", name: "Malayalam" },
  { code: "mt", name: "Maltese" },
  { code: "gv", name: "Manx" },
  { code: "mi", name: "Maori" },
  { code: "mr", name: "Marathi" },
  { code: "mh", name: "Marshallese" },
  { code: "ro", name: "Moldovan, Moldavian, Romanian" },
  { code: "mn", name: "Mongolian" },
  { code: "na", name: "Nauru" },
  { code: "nv", name: "Navajo, Navaho" },
  { code: "nd", name: "Northern Ndebele" },
  { code: "ng", name: "Ndonga" },
  { code: "ne", name: "Nepali" },
  { code: "se", name: "Northern Sami" },
  { code: "no", name: "Norwegian" },
  { code: "nb", name: "Norwegian Bokmål" },
  { code: "nn", name: "Norwegian Nynorsk" },
  { code: "ii", name: "Nuosu, Sichuan Yi" },
  { code: "oc", name: "Occitan (post 1500)" },
  { code: "oj", name: "Ojibwa" },
  { code: "or", name: "Oriya" },
  { code: "om", name: "Oromo" },
  { code: "os", name: "Ossetian, Ossetic" },
  { code: "pi", name: "Pali" },
  { code: "pa", name: "Panjabi, Punjabi" },
  { code: "ps", name: "Pashto, Pushto" },
  { code: "fa", name: "Persian" },
  { code: "pl", name: "Polish" },
  { code: "pt", name: "Portuguese" },
  { code: "qu", name: "Quechua" },
  { code: "rm", name: "Romansh" },
  { code: "rn", name: "Rundi" },
  { code: "ru", name: "Russian" },
  { code: "sm", name: "Samoan" },
  { code: "sg", name: "Sango" },
  { code: "sa", name: "Sanskrit" },
  { code: "sc", name: "Sardinian" },
  { code: "sr", name: "Serbian" },
  { code: "sn", name: "Shona" },
  { code: "sd", name: "Sindhi" },
  { code: "si", name: "Sinhala, Sinhalese" },
  { code: "sk", name: "Slovak" },
  { code: "sl", name: "Slovenian" },
  { code: "so", name: "Somali" },
  { code: "st", name: "Sotho, Southern" },
  { code: "nr", name: "South Ndebele" },
  { code: "es", name: "Spanish, Castilian" },
  { code: "su", name: "Sundanese" },
  { code: "sw", name: "Swahili" },
  { code: "ss", name: "Swati" },
  { code: "sv", name: "Swedish" },
  { code: "tl", name: "Tagalog" },
  { code: "ty", name: "Tahitian" },
  { code: "tg", name: "Tajik" },
  { code: "ta", name: "Tamil" },
  { code: "tt", name: "Tatar" },
  { code: "te", name: "Telugu" },
  { code: "th", name: "Thai" },
  { code: "bo", name: "Tibetan" },
  { code: "ti", name: "Tigrinya" },
  { code: "to", name: "Tonga (Tonga Islands)" },
  { code: "ts", name: "Tsonga" },
  { code: "tn", name: "Tswana" },
  { code: "tr", name: "Turkish" },
  { code: "tk", name: "Turkmen" },
  { code: "tw", name: "Twi" },
  { code: "ug", name: "Uighur, Uyghur" },
  { code: "uk", name: "Ukrainian" },
  { code: "ur", name: "Urdu" },
  { code: "uz", name: "Uzbek" },
  { code: "ve", name: "Venda" },
  { code: "vi", name: "Vietnamese" },
  { code: "vo", name: "Volap_k" },
  { code: "wa", name: "Walloon" },
  { code: "cy", name: "Welsh" },
  { code: "fy", name: "Western Frisian" },
  { code: "wo", name: "Wolof" },
  { code: "xh", name: "Xhosa" },
  { code: "yi", name: "Yiddish" },
  { code: "yo", name: "Yoruba" },
  { code: "za", name: "Zhuang, Chuang" },
  { code: "zu", name: "Zulu" },
];

export const Timezones = [
  {
    name: "(GMT-11:00) Midway Island",
    timezone: "Pacific/Midway",
  },
  {
    name: "(GMT-11:00) Samoa",
    timezone: "US/Samoa",
  },
  {
    name: "(GMT-10:00) Hawaii",
    timezone: "US/Hawaii",
  },
  {
    name: "(GMT-09:00) Alaska",
    timezone: "US/Alaska",
  },
  {
    name: "(GMT-08:00) Pacific Time (US & Canada)",
    timezone: "US/Pacific",
  },
  {
    name: "(GMT-08:00) Tijuana",
    timezone: "America/Tijuana",
  },
  {
    name: "(GMT-07:00) Arizona",
    timezone: "US/Arizona",
  },
  {
    name: "(GMT-07:00) Mountain Time (US & Canada)",
    timezone: "US/Mountain",
  },
  {
    name: "(GMT-07:00) Chihuahua",
    timezone: "America/Chihuahua",
  },
  {
    name: "(GMT-07:00) Mazatlan",
    timezone: "America/Mazatlan",
  },
  {
    name: "(GMT-06:00) Mexico City",
    timezone: "America/Mexico_City",
  },
  {
    name: "(GMT-06:00) Monterrey",
    timezone: "America/Monterrey",
  },
  {
    name: "(GMT-06:00) Saskatchewan",
    timezone: "Canada/Saskatchewan",
  },
  {
    name: "(GMT-06:00) Central Time (US & Canada)",
    timezone: "US/Central",
  },
  {
    name: "(GMT-05:00) Eastern Time (US & Canada)",
    timezone: "US/Eastern",
  },
  {
    name: "(GMT-05:00) Indiana (East)",
    timezone: "US/East-Indiana",
  },
  {
    name: "(GMT-05:00) Bogota",
    timezone: "America/Bogota",
  },
  {
    name: "(GMT-05:00) Lima",
    timezone: "America/Lima",
  },
  {
    name: "(GMT-04:30) Caracas",
    timezone: "America/Caracas",
  },
  {
    name: "(GMT-04:00) Atlantic Time (Canada)",
    timezone: "Canada/Atlantic",
  },
  {
    name: "(GMT-04:00) La Paz",
    timezone: "America/La_Paz",
  },
  {
    name: "(GMT-04:00) Santiago",
    timezone: "America/Santiago",
  },
  {
    name: "(GMT-03:30) Newfoundland",
    timezone: "Canada/Newfoundland",
  },
  {
    name: "(GMT-03:00) Buenos Aires",
    timezone: "America/Buenos_Aires",
  },
  {
    name: "(GMT-03:00) Greenland",
    timezone: "Greenland",
  },
  {
    name: "(GMT-02:00) Stanley",
    timezone: "Atlantic/Stanley",
  },
  {
    name: "(GMT-01:00) Azores",
    timezone: "Atlantic/Azores",
  },
  {
    name: "(GMT-01:00) Cape Verde Is.",
    timezone: "Atlantic/Cape_Verde",
  },
  {
    name: "(GMT) Casablanca",
    timezone: "Africa/Casablanca",
  },
  {
    name: "(GMT) Dublin",
    timezone: "Europe/Dublin",
  },
  {
    name: "(GMT) Lisbon",
    timezone: "Europe/Lisbon",
  },
  {
    name: "(GMT) London",
    timezone: "Europe/London",
  },
  {
    name: "(GMT) Monrovia",
    timezone: "Africa/Monrovia",
  },
  {
    name: "(GMT+01:00) Amsterdam",
    timezone: "Europe/Amsterdam",
  },
  {
    name: "(GMT+01:00) Belgrade",
    timezone: "Europe/Belgrade",
  },
  {
    name: "(GMT+01:00) Berlin",
    timezone: "Europe/Berlin",
  },
  {
    name: "(GMT+01:00) Bratislava",
    timezone: "Europe/Bratislava",
  },
  {
    name: "(GMT+01:00) Brussels",
    timezone: "Europe/Brussels",
  },
  {
    name: "(GMT+01:00) Budapest",
    timezone: "Europe/Budapest",
  },
  {
    name: "(GMT+01:00) Copenhagen",
    timezone: "Europe/Copenhagen",
  },
  {
    name: "(GMT+01:00) Ljubljana",
    timezone: "Europe/Ljubljana",
  },
  {
    name: "(GMT+01:00) Madrid",
    timezone: "Europe/Madrid",
  },
  {
    name: "(GMT+01:00) Paris",
    timezone: "Europe/Paris",
  },
  {
    name: "(GMT+01:00) Prague",
    timezone: "Europe/Prague",
  },
  {
    name: "(GMT+01:00) Rome",
    timezone: "Europe/Rome",
  },
  {
    name: "(GMT+01:00) Sarajevo",
    timezone: "Europe/Sarajevo",
  },
  {
    name: "(GMT+01:00) Skopje",
    timezone: "Europe/Skopje",
  },
  {
    name: "(GMT+01:00) Stockholm",
    timezone: "Europe/Stockholm",
  },
  {
    name: "(GMT+01:00) Vienna",
    timezone: "Europe/Vienna",
  },
  {
    name: "(GMT+01:00) Warsaw",
    timezone: "Europe/Warsaw",
  },
  {
    name: "(GMT+01:00) Zagreb",
    timezone: "Europe/Zagreb",
  },
  {
    name: "(GMT+02:00) Athens",
    timezone: "Europe/Athens",
  },
  {
    name: "(GMT+02:00) Bucharest",
    timezone: "Europe/Bucharest",
  },
  {
    name: "(GMT+02:00) Cairo",
    timezone: "Africa/Cairo",
  },
  {
    name: "(GMT+02:00) Harare",
    timezone: "Africa/Harare",
  },
  {
    name: "(GMT+02:00) Helsinki",
    timezone: "Europe/Helsinki",
  },
  {
    name: "(GMT+02:00) Istanbul",
    timezone: "Europe/Istanbul",
  },
  {
    name: "(GMT+02:00) Jerusalem",
    timezone: "Asia/Jerusalem",
  },
  {
    name: "(GMT+02:00) Kyiv",
    timezone: "Europe/Kiev",
  },
  {
    name: "(GMT+02:00) Minsk",
    timezone: "Europe/Minsk",
  },
  {
    name: "(GMT+02:00) Riga",
    timezone: "Europe/Riga",
  },
  {
    name: "(GMT+02:00) Sofia",
    timezone: "Europe/Sofia",
  },
  {
    name: "(GMT+02:00) Tallinn",
    timezone: "Europe/Tallinn",
  },
  {
    name: "(GMT+02:00) Vilnius",
    timezone: "Europe/Vilnius",
  },
  {
    name: "(GMT+03:00) Baghdad",
    timezone: "Asia/Baghdad",
  },
  {
    name: "(GMT+03:00) Kuwait",
    timezone: "Asia/Kuwait",
  },
  {
    name: "(GMT+03:00) Nairobi",
    timezone: "Africa/Nairobi",
  },
  {
    name: "(GMT+03:00) Riyadh",
    timezone: "Asia/Riyadh",
  },
  {
    name: "(GMT+03:00) Moscow",
    timezone: "Europe/Moscow",
  },
  {
    name: "(GMT+03:30) Tehran",
    timezone: "Asia/Tehran",
  },
  {
    name: "(GMT+04:00) Baku",
    timezone: "Asia/Baku",
  },
  {
    name: "(GMT+04:00) Volgograd",
    timezone: "Europe/Volgograd",
  },
  {
    name: "(GMT+04:00) Muscat",
    timezone: "Asia/Muscat",
  },
  {
    name: "(GMT+04:00) Tbilisi",
    timezone: "Asia/Tbilisi",
  },
  {
    name: "(GMT+04:00) Yerevan",
    timezone: "Asia/Yerevan",
  },
  {
    name: "(GMT+04:30) Kabul",
    timezone: "Asia/Kabul",
  },
  {
    name: "(GMT+05:00) Karachi",
    timezone: "Asia/Karachi",
  },
  {
    name: "(GMT+05:00) Tashkent",
    timezone: "Asia/Tashkent",
  },
  {
    name: "(GMT+05:30) Kolkata",
    timezone: "Asia/Kolkata",
  },
  {
    name: "(GMT+05:45) Kathmandu",
    timezone: "Asia/Kathmandu",
  },
  {
    name: "(GMT+06:00) Ekaterinburg",
    timezone: "Asia/Yekaterinburg",
  },
  {
    name: "(GMT+06:00) Almaty",
    timezone: "Asia/Almaty",
  },
  {
    name: "(GMT+06:00) Dhaka",
    timezone: "Asia/Dhaka",
  },
  {
    name: "(GMT+07:00) Novosibirsk",
    timezone: "Asia/Novosibirsk",
  },
  {
    name: "(GMT+07:00) Bangkok",
    timezone: "Asia/Bangkok",
  },
  {
    name: "(GMT+07:00) Jakarta",
    timezone: "Asia/Jakarta",
  },
  {
    name: "(GMT+08:00) Krasnoyarsk",
    timezone: "Asia/Krasnoyarsk",
  },
  {
    name: "(GMT+08:00) Chongqing",
    timezone: "Asia/Chongqing",
  },
  {
    name: "(GMT+08:00) Hong Kong",
    timezone: "Asia/Hong_Kong",
  },
  {
    name: "(GMT+08:00) Kuala Lumpur",
    timezone: "Asia/Kuala_Lumpur",
  },
  {
    name: "(GMT+08:00) Perth",
    timezone: "Australia/Perth",
  },
  {
    name: "(GMT+08:00) Singapore",
    timezone: "Asia/Singapore",
  },
  {
    name: "(GMT+08:00) Taipei",
    timezone: "Asia/Taipei",
  },
  {
    name: "(GMT+08:00) Ulaan Bataar",
    timezone: "Asia/Ulaanbaatar",
  },
  {
    name: "(GMT+08:00) Urumqi",
    timezone: "Asia/Urumqi",
  },
  {
    name: "(GMT+09:00) Irkutsk",
    timezone: "Asia/Irkutsk",
  },
  {
    name: "(GMT+09:00) Seoul",
    timezone: "Asia/Seoul",
  },
  {
    name: "(GMT+09:00) Tokyo",
    timezone: "Asia/Tokyo",
  },
  {
    name: "(GMT+09:30) Adelaide",
    timezone: "Australia/Adelaide",
  },
  {
    name: "(GMT+09:30) Darwin",
    timezone: "Australia/Darwin",
  },
  {
    name: "(GMT+10:00) Yakutsk",
    timezone: "Asia/Yakutsk",
  },
  {
    name: "(GMT+10:00) Brisbane",
    timezone: "Australia/Brisbane",
  },
  {
    name: "(GMT+10:00) Canberra",
    timezone: "Australia/Canberra",
  },
  {
    name: "(GMT+10:00) Guam",
    timezone: "Pacific/Guam",
  },
  {
    name: "(GMT+10:00) Hobart",
    timezone: "Australia/Hobart",
  },
  {
    name: "(GMT+10:00) Melbourne",
    timezone: "Australia/Melbourne",
  },
  {
    name: "(GMT+10:00) Port Moresby",
    timezone: "Pacific/Port_Moresby",
  },
  {
    name: "(GMT+10:00) Sydney",
    timezone: "Australia/Sydney",
  },
  {
    name: "(GMT+11:00) Vladivostok",
    timezone: "Asia/Vladivostok",
  },
  {
    name: "(GMT+12:00) Magadan",
    timezone: "Asia/Magadan",
  },
  {
    name: "(GMT+12:00) Auckland",
    timezone: "Pacific/Auckland",
  },
  {
    name: "(GMT+12:00) Fiji",
    timezone: "Pacific/Fiji",
  },
];

export const schedule_report = [
  { name: "Default Report", value: "default_report" },
  { name: "Custom Report", value: "custom_report" },
];

export const frequency = [
  { name: "One Time", value: "one_time" },
  { name: "Daily", value: "daily" },
  { name: "Weekly", value: "weekly" },
  { name: "Monthly", value: "monthly" },
  { name: "Yearly", value: "yearly" },
];
