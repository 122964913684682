export default {
  GET_CUSTOMER_LIST_REQUEST: "GET_CUSTOMER_LIST_REQUEST",
  GET_CUSTOMER_LIST_RESPONSE: "GET_CUSTOMER_LIST_RESPONSE",

  GET_CUSTOMER_BY_ID_REQUEST: "GET_CUSTOMER_BY_ID_REQUEST",
  GET_CUSTOMER_BY_ID_RESPONSE: "GET_CUSTOMER_BY_ID_RESPONSE",
  GET_CUSTOMER_BY_ID_RESPONSE_CLEAR: "GET_CUSTOMER_BY_ID_RESPONSE_CLEAR",

  POST_CUSTOMER_REQUEST: "POST_CUSTOMER_REQUEST",
  POST_CUSTOMER_RESPONSE: "POST_CUSTOMER_RESPONSE",
  POST_CUSTOMER_RESPONSE_CLEAR: "POST_CUSTOMER_RESPONSE_CLEAR",

  PUT_CUSTOMER_REQUEST: "PUT_CUSTOMER_REQUEST",
  PUT_CUSTOMER_RESPONSE: "PUT_CUSTOMER_RESPONSE",
  PUT_CUSTOMER_RESPONSE_CLEAR: "PUT_CUSTOMER_RESPONSE_CLEAR",

  PUT_USER_REQUEST: "PUT_USER_REQUEST",
  PUT_USER_RESPONSE: "PUT_USER_RESPONSE",
  PUT_USER_RESPONSE_CLEAR: "PUT_USER_RESPONSE_CLEAR",

  DELETE_CUSTOMER_REQUEST: "DELETE_CUSTOMER_REQUEST",
  DELETE_CUSTOMER_RESPONSE: "DELETE_CUSTOMER_RESPONSE",
  DELETE_CUSTOMER_RESPONSE_CLEAR: "DELETE_CUSTOMER_RESPONSE_CLEAR",
};
