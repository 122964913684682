import { takeLatest, put, call } from "redux-saga/effects";
import AdminUserTypes from "./AdminUserActionTypes";
import {
  deleteAdminUserResponse,
  getAdminUserByIdResponse,
  getAdminUserListResponse,
  postAdminUserResponse,
  putAdminUserResponse,
  putUserRequest,
  putUserResponse,
} from "./AdminUserActions";
import { unAuthorizedData } from "../LoginSaga/LoginActions";
import { axiosDelete, axiosGet, axiosPost, axiosPut } from "../../Utils/axios";

export function* onGetAdminUserList({ payload }) {
  try {
    const { filter, quickFilter = "", keyword = "", count = "" } = payload;

    const sort = filter.sortBy.split("-");

    const url = `admin?page=${
      filter.page
    }&filter=${keyword}&role=${quickFilter}&sort_by=${
      sort[0] ? sort[0] : ""
    }&sort=${sort[1] ? sort[1] : ""}&count=${count}`;

    const response = yield call(() => axiosGet(url).then((res) => res.data));
    yield put(getAdminUserListResponse(response));
  } catch (error) {
    yield put(unAuthorizedData(error));
  }
}

export function* onGetByIdAdminUser({ id }) {
  try {
    const url = `admin/${id}`;
    const response = yield call(() => axiosGet(url).then((res) => res.data));
    yield put(getAdminUserByIdResponse(response));
  } catch (error) {
    yield put(unAuthorizedData(error));
  }
}

export function* onPostAdminUser({ payload }) {
  try {
    const url = `admin`;
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };
    const response = yield call(() =>
      axiosPost(url, payload).then((res) => res.data)
    );
    yield put(postAdminUserResponse(response));
  } catch (error) {
    yield put(unAuthorizedData(error));
  }
}

export function* onPutAdminUser({ payload, id }) {
  try {
    const url = `admin/${id}`;
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };
    const response = yield call(() =>
      axiosPut(url, payload).then((res) => res.data)
    );
    yield put(putAdminUserResponse(response));
  } catch (error) {
    yield put(unAuthorizedData(error));
  }
}

export function* onDeleteAdminUser({ id }) {
  try {
    const url = `admin/${id}`;

    const response = yield call(() => axiosDelete(url).then((res) => res.data));
    yield put(deleteAdminUserResponse(response));
  } catch (error) {
    yield put(unAuthorizedData(error));
  }
}
export function* onPutUser({ payload, id }) {
  try {
    const url = `admin/${id}`;
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };
    const response = yield call(() =>
      axiosPut(url, payload, config).then((res) => res.data)
    );
    yield put(putUserResponse(response));
  } catch (error) {
    yield put(unAuthorizedData(error));
  }
}

export function* AdminUserWatcherSaga() {
  yield takeLatest(
    AdminUserTypes.GET_ADMINUSER_LIST_REQUEST,
    onGetAdminUserList
  );
  yield takeLatest(
    AdminUserTypes.GET_ADMINUSER_BY_ID_REQUEST,
    onGetByIdAdminUser
  );
  yield takeLatest(AdminUserTypes.POST_ADMINUSER_REQUEST, onPostAdminUser);
  yield takeLatest(AdminUserTypes.PUT_ADMINUSER_REQUEST, onPutAdminUser);

  yield takeLatest(AdminUserTypes.DELETE_ADMINUSER_REQUEST, onDeleteAdminUser);
}
