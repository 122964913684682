import CompaniesTypes from "./CompaniesActionTypes";

const initialState = {
  getCompaniesLoading: false,
  getCompaniesResponse: null,
  getCompaniesByidLoading: false,
  getCompaniesByidResponse: null,
  postCompaniesLoading: false,
  postCompaniesResponse: null,
  putCompaniesLoading: false,
  putCompaniesResponse: null,
  deleteCompaniesLoading: false,
  deleteCompaniesResponse: null,
  putUserLoading: false,
  putUserResponse: null,
};

const CompaniesReducer = (state = initialState, action) => {
  switch (action.type) {
    case CompaniesTypes.GET_COMPANIES_LIST_REQUEST:
      return {
        ...state,
        getCompaniesLoading: true,
      };
    case CompaniesTypes.GET_COMPANIES_LIST_RESPONSE:
      return {
        ...state,
        getCompaniesLoading: false,
        getCompaniesResponse: action.payload,
      };
    case CompaniesTypes.GET_COMPANIES_BY_ID_REQUEST:
      return {
        ...state,
        getCompaniesByidLoading: true,
      };
    case CompaniesTypes.GET_COMPANIES_BY_ID_RESPONSE:
      return {
        ...state,
        getCompaniesByidLoading: false,
        getCompaniesByidResponse: action.payload,
      };
    case CompaniesTypes.GET_COMPANIES_BY_ID_RESPONSE_CLEAR:
      return {
        ...state,
        getCompaniesByidResponse: null,
      };
    case CompaniesTypes.POST_COMPANIES_REQUEST:
      return {
        ...state,
        postCompaniesLoading: true,
      };
    case CompaniesTypes.POST_COMPANIES_RESPONSE:
      return {
        ...state,
        postCompaniesLoading: false,
        postCompaniesResponse: action.payload,
      };
    case CompaniesTypes.POST_COMPANIES_RESPONSE_CLEAR:
      return {
        ...state,
        postCompaniesResponse: null,
      };
    case CompaniesTypes.PUT_COMPANIES_REQUEST:
      return {
        ...state,
        putCompaniesLoading: true,
      };
    case CompaniesTypes.PUT_COMPANIES_RESPONSE:
      return {
        ...state,
        putCompaniesLoading: false,
        putCompaniesResponse: action.payload,
      };
    case CompaniesTypes.PUT_COMPANIES_RESPONSE_CLEAR:
      return {
        ...state,
        putCompaniesResponse: null,
      };
    case CompaniesTypes.DELETE_COMPANIES_REQUEST:
      return {
        ...state,
        deleteCompaniesLoading: true,
      };
    case CompaniesTypes.DELETE_COMPANIES_RESPONSE:
      return {
        ...state,
        deleteCompaniesLoading: false,
        deleteCompaniesResponse: action.payload,
      };
    case CompaniesTypes.DELETE_COMPANIES_RESPONSE_CLEAR:
      return {
        ...state,
        deleteCompaniesResponse: null,
      };
    case CompaniesTypes.PUT_USER_REQUEST:
      return {
        ...state,
        putUserLoading: true,
      };
    case CompaniesTypes.PUT_USER_RESPONSE:
      return {
        ...state,
        putUserLoading: false,
        putUserResponse: action.payload,
      };
    case CompaniesTypes.PUT_USER_RESPONSE_CLEAR:
      return {
        ...state,
        putUserResponse: null,
      };
    default:
      return state;
  }
};

export default CompaniesReducer;
