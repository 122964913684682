import AdminUserTypes from "./AdminUserActionTypes";

//----------------team list GET--------------------
export const getAdminUserListRequest = (payload) => ({
  type: AdminUserTypes.GET_ADMINUSER_LIST_REQUEST,
  payload: payload,
});

export const getAdminUserListResponse = (payload) => ({
  type: AdminUserTypes.GET_ADMINUSER_LIST_RESPONSE,
  payload: payload,
});

export const getAdminUserByIdRequest = (id) => ({
  type: AdminUserTypes.GET_ADMINUSER_BY_ID_REQUEST,
  id: id,
});

export const getAdminUserByIdResponse = (payload) => ({
  type: AdminUserTypes.GET_ADMINUSER_BY_ID_RESPONSE,
  payload: payload,
});

export const getAdminUserByIdResponseClear = () => ({
  type: AdminUserTypes.GET_ADMINUSER_BY_ID_RESPONSE_CLEAR,
});
//----------------team POST--------------------------
export const postAdminUserRequest = (payload) => ({
  type: AdminUserTypes.POST_ADMINUSER_REQUEST,
  payload: payload,
});

export const postAdminUserResponse = (payload) => ({
  type: AdminUserTypes.POST_ADMINUSER_RESPONSE,
  payload: payload,
});

export const postAdminUserResponseClear = () => ({
  type: AdminUserTypes.POST_ADMINUSER_RESPONSE_CLEAR,
});

//----------------team PUT----------------------------
export const putAdminUserRequest = (payload, id) => ({
  type: AdminUserTypes.PUT_ADMINUSER_REQUEST,
  payload: payload,
  id: id,
});

export const putAdminUserResponse = (payload) => ({
  type: AdminUserTypes.PUT_ADMINUSER_RESPONSE,
  payload: payload,
});

export const putAdminUserResponseClear = () => ({
  type: AdminUserTypes.PUT_ADMINUSER_RESPONSE_CLEAR,
});

//--------------team DELETE----------------------------
export const deleteAdminUserRequest = (id) => ({
  type: AdminUserTypes.DELETE_ADMINUSER_REQUEST,
  id: id,
});

export const deleteAdminUserResponse = (payload) => ({
  type: AdminUserTypes.DELETE_ADMINUSER_RESPONSE,
  payload: payload,
});

export const deleteAdminUserResponseClear = () => ({
  type: AdminUserTypes.DELETE_ADMINUSER_RESPONSE_CLEAR,
});

//------- Put User ----
export const putUserRequest = (payload, id) => ({
  type: AdminUserTypes.PUT_USER_REQUEST,
  payload: payload,
  id: id,
});

export const putUserResponse = (payload) => ({
  type: AdminUserTypes.PUT_USER_RESPONSE,
  payload: payload,
});

export const putUserResponseClear = () => ({
  type: AdminUserTypes.PUT_USER_RESPONSE_CLEAR,
});
