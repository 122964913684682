import React, { useEffect } from "react";
import CustomButton from "../../../Components/Atoms/CustomButtoncon";
import Whitespace from "../../../Components/Molecules/Whitespace";
import InputField from "../../../Components/Atoms/InputField";
import { useFormik } from "formik";
import * as Yup from "yup";

import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import ComponetTitle from "../../../Components/Organisms/ComponetTitle";
import {
  changePasswordRequest,
  changePasswordResClear,
} from "../../../Redux/LoginSaga/LoginActions";
import toast from "react-hot-toast";
let firstEle = true;
export default function Changepassword() {
  const navigate = useNavigate();
  //  const id = props.userId;

  const { changePasswordResponse } = useSelector((state) => state.authState);

  const { user } = useSelector((state) => state.authState);

  const dispatch = useDispatch();

  const scrollIntoView = (ele) => {
    if (firstEle) {
      ele.scrollIntoView({ behavior: "smooth", block: "center" });
      firstEle = false;
    }
  };

  const formik = useFormik({
    initialValues: {
      currentpassword: "",
      password: "",
      confirmpassword: "",
    },
    onSubmit: (values, { setSubmitting }) => {
      setTimeout(() => {
        setSubmitting(false);
      }, 400);

      dispatch(changePasswordRequest(values, user.data._id));
    },

    validationSchema: Yup.object().shape({
      currentpassword: Yup.string().required("Required"),

      password: Yup.string()
        .required("Required")
        .matches(
          /^\s*\S[\s\S]*$/g,
          "Password field cannot contain only blankspaces"
        )
        .notOneOf(
          [Yup.ref("currentpassword"), ""],
          " current password and new password cannot be same"
        )
        .matches(/[a-z]/, "Password must have atleast 1 small letter.")
        .matches(/[A-Z]/, "Password must have atleast 1 capital letter.")
        .matches(
          /^(?=.*\d)(?=.*[!@#$%^&*])/,
          "Password must have atleast 1 num & 1 special letter."
        )
        .matches(/^.{8,}$/, "Minimum of 8 characters"),

      confirmpassword: Yup.string()
        .required("Required")
        .matches(
          /^\s*\S[\s\S]*$/g,
          "Confirm password this field cannot contain only blankspaces"
        )
        .oneOf([Yup.ref("password"), ""], "Password must match"),
    }),
  });

  useEffect(() => {
    if (changePasswordResponse?.type === "success") {
      toast.success("Password Changed Sucessfully");
      formik.resetForm();
    } else if (changePasswordResponse?.response?.data?.type === "error") {
      toast.error(changePasswordResponse?.response?.data?.message);
    }

    return () => {
      dispatch(changePasswordResClear());
    };
  }, [changePasswordResponse]);

  return (
    <Whitespace>
      <>
        <ComponetTitle title="CHANGE PASSWORD" />
        <form onSubmit={formik.handleSubmit} className="passoword-form">
          <div className="passoword-inputs">
            <div className="passoword-input">
              <InputField
                value={formik.values.currentpassword}
                onChange={formik.handleChange}
                name="currentpassword"
                labelText="Current Password"
                placeholder="Enter Current Password"
                type="password"
                required="true"
                onBlur={formik.handleBlur}
                errorMsg={
                  formik.touched.currentpassword &&
                  formik.errors?.currentpassword
                }
              />
            </div>
            <div className="passoword-input">
              <InputField
                value={formik.values.password}
                onChange={formik.handleChange}
                name="password"
                labelText="New Password"
                placeholder="Enter New Password"
                type="password"
                required="true"
                onBlur={formik.handleBlur}
                errorMsg={formik.touched.password && formik.errors?.password}
              />
            </div>
            <div className="passoword-input">
              <InputField
                value={formik.values.confirmpassword}
                onChange={formik.handleChange}
                name="confirmpassword"
                labelText="Confirm Password"
                placeholder="Enter Confirm Password"
                type="password"
                required="true"
                onBlur={formik.handleBlur}
                errorMsg={
                  formik.touched.confirmpassword &&
                  formik.errors?.confirmpassword
                }
              />
            </div>
            <div className="passoword-button">
              <CustomButton
                size="large"
                btnType={"primary"}
                btnClass={"py-10 w-90"}
                onClick={() => (firstEle = true)}
              >
                Change
              </CustomButton>
            </div>
          </div>
        </form>
      </>
    </Whitespace>
  );
}
