import React, { useEffect, useState } from "react";
import "./Dashboard.css";
// import BarChart from "./BarChart/Barchart";
// import PieChart from "./PieChart/Piechart";
// import LineChart from "./LineChart/LineChart";
import { FiberManualRecord, KeyboardArrowDown } from "@mui/icons-material";
import { gwayloginlogo } from "../../Utils/Utils";
import { Button, Tooltip } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { useDispatch, useSelector } from "react-redux";
import HelmetData from "../../Utils/HelmetData";
import DashBoardChart from "../../Components/Molecules/DashBoardChart";
import Dropdown from "../../Components/Atoms/Dropdown";
import { useNavigate } from "react-router-dom";
import TableButton from "../../Components/Molecules/TableButton";
import { TableIconConstant, sortList } from "../../Utils/constants";
import {
  getDashboardRequest,
  getRentedListRequest,
  putRentedResponseClear,
} from "../../Redux/Rented/RentedActions";
import NoRecords from "../../Components/Molecules/NoRecords";
import Model from "../../Components/Organisms/ModelComponent";
// import RentFrom from "../RentForm/RentFrom";
// import RentFromView from "../RentForm/RentFromView";
// import RentFromEdit from "../RentForm/RentFromEdit";
import toast from "react-hot-toast";
import { getCompaniesListRequest } from "../../Redux/Companies/CompaniesActions";

const Dashboard = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [open1, setOpen1] = useState(false);
  const handleOpen1 = () => setOpen1(true);
  const handleClose1 = () => {
    setOpen1(false);
    setEach("");
  };
  const [open, setOpen] = useState(false);
  const [each, setEach] = useState("");
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setEach("");
  };
  const [value, setValue] = useState("");
  const [page, setPage] = useState(1);
  const [quickFilter, setQuickFilter] = useState("");
  const [sortBy, setSortBy] = useState("");
  const {
    postRentedResponse,
    postRentedLoading,
    getRentedResponse,
    getDashboardResponse,
    getRentedLoading,
    putRentedLoading,
    putRentedResponse,
  } = useSelector((state) => state.rented);
  const { getCompaniesLoading, getCompaniesResponse } = useSelector(
    (state) => state.companies
  );
  useEffect(() => {
    if (!getDashboardResponse) return;
    setValue(getDashboardResponse.data);
  }, [getDashboardResponse]);
  useEffect(() => {
    if (!putRentedResponse) return;
    if (putRentedResponse?.type === "success") {
      toast.success("Rent Updated Successfully");
      handleClose1();
    }
    dispatch(putRentedResponseClear());
  }, [putRentedResponse]);
  useEffect(() => {
    if (putRentedLoading || postRentedLoading) return;
    dispatch(getDashboardRequest());
    dispatch(
      getCompaniesListRequest({
        quickFilter,
        filter: { sortBy, page },
        keyword: "",
      })
    );
  }, [putRentedLoading, postRentedLoading, sortBy]);

  return (
    <>
      <Model
        width={"80%"}
        height={"70%"}
        maxHeight={"100%"}
        header={"rentHeader"}
        handleClose={handleClose}
        open={open}
        Title={`Rent Material - # ${each?.order_id}`}
      >
        {/* <RentFromView each={each} handleClose={handleClose} /> */}
      </Model>
      <Model
        width={"100%"}
        height={"100%"}
        maxHeight={"100%"}
        header={"rentHeader"}
        handleClose={handleClose1}
        open={open1}
        Title={`Rent Material - # ${each?.order_id}`}
      >
        {/* <RentFromEdit each={each} handleClose={handleClose1} /> */}
      </Model>
      <HelmetData title={`Dashboard`} />
      <div className="dashboard_container">
        <div className="col_4">
          <DashBoardChart
            title={"List Of Companies"}
            value={value?.company_count}
            background={"#FEEEF1"}
            color={"#92031E"}
          />
          <DashBoardChart
            title={"List Of  Users"}
            value={value?.user_count}
            background={"#FEF2DC"}
            color={"#6C4600"}
          />
          <DashBoardChart
            title={"Rent To Be Received"}
            //  value={`₹${Number(value?.balance_amount).toFixed(2)}`}
            background={"#D9FAE7"}
            color={"#005725"}
          />
          <DashBoardChart
            title={"Total Rent Collected"}
            //   value={`₹${Number(value?.receveid_amount).toFixed(2)}`}
            background={"#D9E6FA"}
            color={"#002B6A"}
          />
        </div>
        <div className="filter_table_container">
          <p className="filtertable_para">
            Companies
            <span onClick={() => navigate("/companies")}>View all</span>
          </p>
          <div className="sort_by_filter">
            <p>Sort By</p>{" "}
            <div className="filter_input">
              <Dropdown
                value={sortBy}
                onChange={(e) => {
                  setSortBy(e.target.value);
                }}
                name="sortBy"
                setValue="value"
                placeholder="Select"
                data_testid="role"
                required="true"
                data={sortList}
              />
            </div>
          </div>
        </div>

        <div className="tableWrpr">
          <table data-testid="table-container" className="table-container">
            <tbody>
              <tr className="td_header_color">
                <th className="th">Sr.No</th>
                <th className="th">Company Name</th>
                <th className="th">Email</th>
                <th className="th">Phone</th>
                <th className="th">User Count</th>
              </tr>
              {/* {EmployeeListPageResponse?.data?.length > 0
                ? EmployeeListPageResponse?.data? */}
              {getCompaniesResponse?.data?.data?.length > 0 ? (
                getCompaniesResponse?.data?.data?.map((each, index) => {
                  return (
                    <tr className="tr-container" key={index}>
                      <td className="td td-index">{index + 1}</td>

                      <td className="td">{each?.name}</td>
                      <td className="td">{each?.email}</td>
                      <td className="td">{each?.phone}</td>
                      <td className="td">{each?.user_count}</td>
                    </tr>
                  );
                })
              ) : (
                <NoRecords />
              )}
              {/* // : !EmployeeListPageLoading && <NoRecords length="5" />} */}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default Dashboard;
