import React, { useEffect, useState } from "react";
import "./index.css";
import Dropdown from "../../Components/Atoms/Dropdown";
import AddIcon from "@mui/icons-material/Add";
import CustomButton from "../../Components/Atoms/CustomButtoncon";
import Model from "../../Components/Organisms/ModelComponent";
import TableButton from "../../Components/Molecules/TableButton";
import { TableIconConstant, sortList } from "../../Utils/constants";
import { useDispatch, useSelector } from "react-redux";
import {
  getUsersListRequest,
  postUsersResponseClear,
  putUsersResponseClear,
} from "../../Redux/Users/UsersActions";
import toast from "react-hot-toast";
import NoRecords from "../../Components/Molecules/NoRecords";
import HelmetData from "../../Utils/HelmetData";
import CountPerPage from "../../Components/Organisms/CountperPage";
import AddUsers from "./coms/AddUsers";
import { useNavigate, useParams } from "react-router-dom";

const Users = () => {
  const { id } = useParams();
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const [each, setEach] = useState(null);
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const [totalpage, setTotalpage] = useState(1);
  const [pagelimit, setPagelimit] = useState(10);
  const [quickFilter, setQuickFilter] = useState("");
  const [sortBy, setSortBy] = useState("");
  const handleClose = () => {
    setOpen(false);
  };
  useEffect(() => {
    if (open) return;
    setEach(null);
  }, [open]);
  const {
    postUsersResponse,
    postUsersLoading,
    getUsersResponse,
    putUsersLoading,
    putUsersResponse,
  } = useSelector((state) => state.user);
  console.log(getUsersResponse);
  useEffect(() => {
    if (!getUsersResponse) return;

    setTotalpage(getUsersResponse?.data?.total_pages);
  }, [getUsersResponse]);
  useEffect(() => {
    if (putUsersLoading || postUsersLoading) return;

    dispatch(
      getUsersListRequest({
        quickFilter,
        filter: { sortBy, page },
        keyword: "",
        company_id: id,
      })
    );
  }, [putUsersLoading, postUsersLoading, page, pagelimit, sortBy]);
  console.log(getUsersResponse);
  const dispatch = useDispatch();
  useEffect(() => {
    if (!putUsersResponse) return;
    if (putUsersResponse?.type === "success") {
      toast.success("Users Updated Successfully");
      handleClose();
    }
    dispatch(putUsersResponseClear());
  }, [putUsersResponse]);
  useEffect(() => {
    if (!postUsersResponse) return;
    if (postUsersResponse?.type === "success") {
      toast.success("Users Created Successfully");
      handleClose();
    }

    dispatch(postUsersResponseClear());
  }, [postUsersResponse]);

  return (
    <>
      <HelmetData title="Users" />
      <Model
        handleClose={handleClose}
        open={open}
        Title={each ? "Edit User" : "Add New User"}
      >
        <AddUsers each={each} handleClose={handleClose} />
      </Model>
      <div className="matrial_body">
        {" "}
        <div className="filter_table_container">
          <div></div>
          <div className="sort_by_filter">
            <p>Sort By</p>{" "}
            <div className="filter_input">
              <Dropdown
                value={sortBy}
                onChange={(e) => {
                  setSortBy(e.target.value);
                }}
                name="sortBy"
                setValue="value"
                placeholder="Select"
                data_testid="role"
                required="true"
                data={sortList}
              />
            </div>
            <CustomButton
              size="medium"
              btnType={"primary"}
              buttonType={"add"}
              btnClass={"py-10 "}
              onClick={() => {
                handleOpen();
              }}
            >
              Add New User
            </CustomButton>
          </div>
        </div>
        <div className="tableWrpr">
          <table data-testid="table-container" className="table-container">
            <tbody>
              <tr className="td_header_color">
                <th className="th">Sr.No</th>
                <th className="th">User Name</th>
                <th className="th">Phone Number</th>
                <th className="th">Email </th>
                <th className="th">Actions</th>
              </tr>

              {getUsersResponse?.data?.length ? (
                getUsersResponse?.data?.map((each, index) => {
                  return (
                    <tr className="tr-container" key={index}>
                      <td className="td td-index">{index + 1}</td>
                      <td className="td">
                        {each?.first_name} {each?.last_name}
                      </td>

                      <td className="td">{each?.phone}</td>
                      <td className="td">{each?.email}</td>

                      <td className="td">
                        <div className="td-actions">
                          {/* <TableButton
                            buttonType={TableIconConstant.view}
                            onClickBtn={() => {
                              navigate(`${each._id}`);
                            }}
                            size="large"
                          /> */}

                          <TableButton
                            buttonType={TableIconConstant.edit}
                            onClickBtn={
                              () => {
                                setEach(each);
                                handleOpen();
                              }
                              // navigateEdit(each._id)
                            }
                            size="large"
                          />

                          {/* <TableButton
                          size="large"
                          buttonType={TableIconConstant.status}
                          statusVal={each.status === "Active"}
                          onClickBtn={() => {
                            //   toggleEmployeeStatus(each._id);
                          }}
                        /> */}
                        </div>
                      </td>
                    </tr>
                  );
                })
              ) : (
                <NoRecords />
              )}
            </tbody>
          </table>
        </div>
        <CountPerPage
          pagelimit={pagelimit}
          totalpage={totalpage}
          setPagelimit={setPagelimit}
          setPage={setPage}
        />
      </div>
    </>
  );
};

export default Users;
