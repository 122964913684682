import CustomerTypes from "./CustomerActionTypes";

const initialState = {
  getCustomerLoading: false,
  getCustomerResponse: null,
  getCustomerByidLoading: false,
  getCustomerByidResponse: null,
  postCustomerLoading: false,
  postCustomerResponse: null,
  putCustomerLoading: false,
  putCustomerResponse: null,
  deleteCustomerLoading: false,
  deleteCustomerResponse: null,

  putUserLoading: false,
  putUserResponse: null,
};

const CustomerReducer = (state = initialState, action) => {
  switch (action.type) {
    case CustomerTypes.GET_CUSTOMER_LIST_REQUEST:
      return {
        ...state,
        getCustomerLoading: true,
      };
    case CustomerTypes.GET_CUSTOMER_LIST_RESPONSE:
      return {
        ...state,
        getCustomerLoading: false,
        getCustomerResponse: action.payload,
      };
    case CustomerTypes.GET_CUSTOMER_BY_ID_REQUEST:
      return {
        ...state,
        getCustomerByidLoading: true,
      };
    case CustomerTypes.GET_CUSTOMER_BY_ID_RESPONSE:
      return {
        ...state,
        getCustomerByidLoading: false,
        getCustomerByidResponse: action.payload,
      };
    case CustomerTypes.GET_CUSTOMER_BY_ID_RESPONSE_CLEAR:
      return {
        ...state,
        getCustomerByidResponse: null,
      };
    case CustomerTypes.POST_CUSTOMER_REQUEST:
      return {
        ...state,
        postCustomerLoading: true,
      };
    case CustomerTypes.POST_CUSTOMER_RESPONSE:
      return {
        ...state,
        postCustomerLoading: false,
        postCustomerResponse: action.payload,
      };
    case CustomerTypes.POST_CUSTOMER_RESPONSE_CLEAR:
      return {
        ...state,
        postCustomerResponse: null,
      };
    case CustomerTypes.PUT_CUSTOMER_REQUEST:
      return {
        ...state,
        putCustomerLoading: true,
      };
    case CustomerTypes.PUT_CUSTOMER_RESPONSE:
      return {
        ...state,
        putCustomerLoading: false,
        putCustomerResponse: action.payload,
      };
    case CustomerTypes.PUT_CUSTOMER_RESPONSE_CLEAR:
      return {
        ...state,
        putCustomerResponse: null,
      };
    case CustomerTypes.DELETE_CUSTOMER_REQUEST:
      return {
        ...state,
        deleteCustomerLoading: true,
      };
    case CustomerTypes.DELETE_CUSTOMER_RESPONSE:
      return {
        ...state,
        deleteCustomerLoading: false,
        deleteCustomerResponse: action.payload,
      };
    case CustomerTypes.DELETE_CUSTOMER_RESPONSE_CLEAR:
      return {
        ...state,
        deleteCustomerResponse: null,
      };
    case CustomerTypes.PUT_USER_REQUEST:
      return {
        ...state,
        putUserLoading: true,
      };
    case CustomerTypes.PUT_USER_RESPONSE:
      return {
        ...state,
        putUserLoading: false,
        putUserResponse: action.payload,
      };
    case CustomerTypes.PUT_USER_RESPONSE_CLEAR:
      return {
        ...state,
        putUserResponse: null,
      };
    default:
      return state;
  }
};

export default CustomerReducer;
