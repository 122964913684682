import { takeLatest, put, call } from "redux-saga/effects";
import RentedTypes from "./RentedActionTypes";
import {
  deleteRentedResponse,
  getDashboardResponse,
  getInvoiceResponse,
  getRentedByIdResponse,
  getRentedListResponse,
  postRentedResponse,
  putRentedResponse,
} from "./RentedActions";
import { unAuthorizedData } from "../LoginSaga/LoginActions";
import { axiosDelete, axiosGet, axiosPost, axiosPut } from "../../Utils/axios";

export function* onGetRentedList({ payload }) {
  try {
    const { filter, quickFilter = "", keyword = "", count = "" } = payload;

    const sort = filter.sortBy.split("-");

    const url = `rented?page=${
      filter.page
    }&filter=${keyword}&role=${quickFilter}&sort_by=${
      sort[0] ? sort[0] : ""
    }&sort=${sort[1] ? sort[1] : ""}&count=${count}`;

    const response = yield call(() => axiosGet(url).then((res) => res.data));
    yield put(getRentedListResponse(response));
  } catch (error) {
    yield put(unAuthorizedData(error));
  }
}

export function* onGetDashboard() {
  try {
    const url = `company/admin/dashboard`;
    const response = yield call(() => axiosGet(url).then((res) => res.data));
    yield put(getDashboardResponse(response));
  } catch (error) {
    yield put(unAuthorizedData(error));
  }
}

export function* onGetByIdRented({ id }) {
  try {
    const url = `rented/${id}`;
    const response = yield call(() => axiosGet(url).then((res) => res.data));
    yield put(getRentedByIdResponse(response));
  } catch (error) {
    yield put(unAuthorizedData(error));
  }
}

export function* onGetInvoice({ id }) {
  try {
    const url = `rented/pdf/${id}`;
    const response = yield call(() => axiosGet(url).then((res) => res.data));
    yield put(getInvoiceResponse(response));
  } catch (error) {
    yield put(unAuthorizedData(error));
  }
}

export function* onPostRented({ payload }) {
  try {
    const url = `rented`;

    const response = yield call(() =>
      axiosPost(url, payload).then((res) => res.data)
    );
    yield put(postRentedResponse(response));
  } catch (error) {
    yield put(unAuthorizedData(error));
  }
}

export function* onPutRented({ payload, id }) {
  try {
    const url = `rented/${id}`;

    const response = yield call(() =>
      axiosPut(url, payload).then((res) => res.data)
    );
    yield put(putRentedResponse(response));
  } catch (error) {
    yield put(unAuthorizedData(error));
  }
}

export function* onDeleteRented({ id }) {
  try {
    const url = `rented/${id}`;

    const response = yield call(() => axiosDelete(url).then((res) => res.data));
    yield put(deleteRentedResponse(response));
  } catch (error) {
    yield put(unAuthorizedData(error));
  }
}

export function* RentedWatcherSaga() {
  yield takeLatest(RentedTypes.GET_RENTED_LIST_REQUEST, onGetRentedList);
  yield takeLatest(RentedTypes.GET_DASHBOARD_REQUEST, onGetDashboard);
  yield takeLatest(RentedTypes.GET_RENTED_BY_ID_REQUEST, onGetByIdRented);
  yield takeLatest(RentedTypes.POST_RENTED_REQUEST, onPostRented);
  yield takeLatest(RentedTypes.GET_INVOICE_REQUEST, onGetInvoice);

  yield takeLatest(RentedTypes.PUT_RENTED_REQUEST, onPutRented);
  yield takeLatest(RentedTypes.DELETE_RENTED_REQUEST, onDeleteRented);
}
