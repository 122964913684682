import React from "react";
import ComponetTitle from "../ComponetTitle";
import "./index.css";

export default function SettingsItemList(props) {
  return (
    <div className="SettingsItemList_items_container">
      <div className="SettingsItemList_title">
        <ComponetTitle title={props.title} />
      </div>

      {props.list.map((each) => (
        <div
          className={
            each === props.current
              ? "SettingsItemList_items active"
              : "SettingsItemList_items "
          }
          onClick={() => props.page(each)}
          onKeyDown={() => {}}
        >
          <h3 className="inside_item_name">{each}</h3>
        </div>
      ))}
    </div>
  );
}
