import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import Dashboard from "../Pages/Dashboard/Index";
// import Login from "../Pages/Login/Index";
import Layout from "../Components/Organisms/Layout/Layout";

// import Company from "../Pages/Company/Company";

import ProtectedRoute from "./ProtectedRoute";
import { useSelector } from "react-redux";
import LoginPage from "../Pages/LoginPage";
import ForgotPassword from "../Pages/LoginPage/Forget_password";
import ResetPassword from "../Pages/LoginPage/Reset_Password";
import Myprofile from "../Pages/Myprofile";
import Companies from "../Pages/Companies";
import AdminUser from "../Pages/AdminUser";
import Users from "../Pages/Users";

const Router = (props) => {
  const { user } = useSelector((state) => state.authState);
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/login" element={<LoginPage />} />

          <Route path="/forgot-password" element={<ForgotPassword />} />
          {/*RESET PASSWORD */}
          <Route path="/reset_password" element={<ResetPassword />} />
          {/* PROTECTED ROUTES */}
          <Route element={<ProtectedRoute />}>
            <Route element={<Layout />}>
              <Route path="/" element={<Dashboard />} />
              <Route path="/myprofile" element={<Myprofile />} />
              <Route path="/companies" element={<Companies />} />
              <Route path="/companies/:id" element={<Users />} />
              <Route path="/adminuser" element={<AdminUser />} />
            </Route>
          </Route>
        </Routes>
      </BrowserRouter>
    </>
  );
};

export default Router;
