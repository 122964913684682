import React from "react";
import "./index.css";
import { TableIconConstant } from "../../../Utils/constants";
import AddIcon from "@mui/icons-material/Add";
import InventoryIcon from "@mui/icons-material/Inventory";

const CustomButton = (props) => {
  const { buttonType, size, btnType, btnClass } = props;
  const renderBtn = () => {
    switch (buttonType) {
      case TableIconConstant.add:
        return <AddIcon />;
      case TableIconConstant.inventory:
        return <InventoryIcon />;
    }
  };
  const getButtonClass = () => {
    switch (btnType) {
      case "terinary":
        return "terinary";
      case "primary":
        return "primButton";
      case "btn_red":
        return "btn_red";
      default:
        return "secButton";
    }
  };
  return (
    <div className={`btnWrpr ${btnClass || ""} ${getButtonClass()} ${size}`}>
      <button
        onClick={props.onClick}
        type={props.type}
        disabled={props.disabled}
      >
        {renderBtn()}
        {props.children}
      </button>
    </div>
  );
};

export default CustomButton;
