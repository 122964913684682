import React from "react";

export default function InputLable(props) {
  const { type, required, labelText, htmlFor } = props;
  const labelClass =
    type === "checkbox" || type === "radio"
      ? "input-label-checkbox"
      : "input-label";
  return (
    <label htmlFor={htmlFor} className={labelClass} data-testid={labelClass}>
      {labelText}
      {required ? <span style={{ color: "red" }}>*</span> : null}
    </label>
  );
}
