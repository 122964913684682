import React, { useEffect, useRef, useState } from "react";
import "./AddUsers.css";
import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";

import CustomCancelSubmit from "../../../Components/Molecules/CustomCancelSubmit";
import InputField from "../../../Components/Atoms/InputField";
import {
  postUsersRequest,
  putUsersRequest,
} from "../../../Redux/Users/UsersActions";
import {
  expression,
  stateList,
  countryList,
  imageFileFormat,
} from "../../../Utils/constants";
import PhoneNumber from "../../../Components/Molecules/Phonenumber";
import Dropdown from "../../../Components/Atoms/Dropdown";
import userdummy from "../../../asset/gway-logo-login.png";
import { appUrl } from "../../../Utils/axios";
import { useParams } from "react-router-dom";

function AddUsers(props) {
  const dispatch = useDispatch();
  const { id } = useParams();
  let [stateListUser, setStateListUser] = useState([]);
  const [checkphone, setCheckphone] = useState(0);
  const { each } = props;
  const [image, setImage] = useState(userdummy);
  const hiddenFileInput = useRef(null);
  const handleChange = (e) => {
    const filename = e?.currentTarget?.files[0]?.name?.split(".");
    if (filename && filename[0]) {
      const format = filename[filename?.length - 1];
      if (imageFileFormat.includes(format)) {
        formik.setFieldValue("profile", e.currentTarget.files[0]);
        setImage(URL.createObjectURL(e.currentTarget.files[0]));
      }
    }
  };
  const handleClick = () => {
    hiddenFileInput.current.click();
  };
  useEffect(() => {
    if (!each) return;
    for (let key in each) {
      formik.setFieldValue(key, each[key]);
    }
    if (each?.profile) {
      setImage(appUrl + "/" + each?.profile);
    }
    //  formik.setFieldValue("description", each.description);
  }, [each]);
  const formik = useFormik({
    initialValues: {
      first_name: "",
      last_name: "",
      phone: "",
      profile: "",
      email: "",
      country: "",
      address: "",
      state: "",
      city: "",
      zipcode: "",
    },
    onSubmit: (values) => {
      if (each) {
        dispatch(putUsersRequest(values, each._id));
      } else {
        values = {
          ...values,
          company_id: id,
        };
        dispatch(postUsersRequest(values));
      }
    },
    validationSchema: Yup.object().shape({
      email: Yup.string()
        .matches(expression.email, "Invalid email")
        .matches(expression.Without_space, "Email should not contain spaces"),
      first_name: Yup.string()
        .required("Required")
        .min(3, "Name must be at least 3 characters")
        .max(50, "Name must be at most 50 characters")
        .matches(expression.char, "name should only have characters")
        .matches(/^(?!\s).+(?<!\s)$/, "name should not contain spaces"),
      company_name: Yup.string()

        .min(3, "must be at least 3 characters")
        .max(50, "must be at most 50 characters")
        .matches(expression.char, "company name should only have characters")
        .matches(/^(?!\s).+(?<!\s)$/, "company name should not contain spaces"),
      address: Yup.string()

        .min(3, " must be at least 3 characters")
        .max(50, "must be at most 50 characters")
        .matches(expression.min_one_char, "should only have characters")
        .matches(/^(?!\s).+(?<!\s)$/, "should not contain spaces"),
      city: Yup.string()

        .min(3, "must be at least 1 characters")
        .max(50, "must be at most 50 characters")
        .matches(expression.char, "city should only have characters")
        .matches(/^(?!\s).+(?<!\s)$/, "city  should not contain spaces"),
    }),
    phone: Yup.string().test(
      "check phone",
      "Phone number is invalid",
      (hour) => {
        return !(hour && checkphone !== hour.length);
      }
    ),
  });
  useEffect(() => {
    if (formik.values?.country) {
      const selectedCountry = countryList.find(
        (each) => each.name === formik.values?.country
      );
      console.log(selectedCountry, formik.values?.country);
      let updatedStateListUser = stateList.getStatesOfCountry(
        selectedCountry?.isoCode
      );
      setStateListUser(updatedStateListUser);
      formik.setFieldValue("state", each?.state);
    }
  }, [formik.values.country]);
  console.log(stateListUser);
  return (
    <>
      <form className="customer_model_form">
        <div className="black-ine-container"></div>
        {/* <div className="customer_file_upload">
          <div
            className="image_customer_container_admin "
            onClick={handleClick}
          >
            {image ? (
              <img className="company_logo" src={image} />
            ) : (
              <AccountCircleIcon sx={{ fontSize: "101px" }} className="" />
            )}
            <input
              type="file"
              onChange={handleChange}
              ref={hiddenFileInput}
              style={{ display: "none" }}
              accept="image/jpg,image/png,image/jpeg"
            />
            {/* <div className="customer_upload_button" onClick={handleClick}>
              Upload
            </div> 
          </div>
        </div> */}

        <div className="col_2">
          <InputField
            value={formik.values.first_name}
            onChange={formik.handleChange}
            name="first_name"
            labelText="First Name"
            placeholder="Enter Name"
            required={true}
            onBlur={formik.handleBlur}
            errorMsg={formik.touched.first_name && formik.errors?.first_name}
            type="text"
          />
          <InputField
            value={formik.values.last_name}
            onChange={formik.handleChange}
            name="last_name"
            labelText="Last Name"
            placeholder="Enter Name"
            // required={true}
            onBlur={formik.handleBlur}
            errorMsg={formik.touched.last_name && formik.errors?.last_name}
            type="text"
          />

          <PhoneNumber
            value={formik.values.phone}
            onChange={formik.setFieldValue}
            labelText="Phone Number"
            name="phone"
            placeholder="Enter phone number"
            // scrollIntoView={scrollIntoView}
            onBlur={formik.handleBlur}
            validcheck={setCheckphone}
            errorMsg={formik.touched.phone && formik.errors?.phone}
          />
          <InputField
            value={formik.values.email}
            onChange={formik.handleChange}
            name="email"
            labelText="Email Address"
            placeholder="Enter Email"
            onBlur={formik.handleBlur}
            errorMsg={formik.touched.email && formik.errors?.email}
            type="email"
          />
        </div>

        <InputField
          value={formik.values.address}
          onChange={formik.handleChange}
          name="address"
          labelText="Address"
          placeholder="Enter address"
          onBlur={formik.handleBlur}
          errorMsg={formik.touched.address && formik.errors?.address}
          type="text"
        />
        <div className="col_2">
          <Dropdown
            value={formik.values.country}
            onChange={formik.handleChange}
            labelText="Country"
            name="country"
            placeholder="Select your country"
            setValue="name"
            data_testid="country"
            errorMsg={formik.touched.country && formik.errors?.country}
            onBlur={formik.handleBlur}
            data={countryList}
          />

          <Dropdown
            value={formik.values.state}
            onChange={formik.handleChange}
            labelText="State"
            name="state"
            placeholder="Select your state"
            setValue="name"
            data_testid="state"
            errorMsg={formik.touched.state && formik.errors?.state}
            onBlur={formik.handleBlur}
            data={stateListUser}
          />
          <InputField
            value={formik.values.city}
            onChange={formik.handleChange}
            name="city"
            labelText="City"
            placeholder="Enter City"
            onBlur={formik.handleBlur}
            errorMsg={formik.touched.city && formik.errors?.city}
            type="text"
          />
          <InputField
            value={formik.values.zipcode}
            onChange={formik.handleChange}
            name="zipcode"
            labelText="Zipcode"
            placeholder="Enter Zipcode"
            onBlur={formik.handleBlur}
            errorMsg={formik.touched.zipcode && formik.errors?.zipcode}
            type="text"
          />
        </div>
        <div className="black-ine-container"></div>
      </form>
      <CustomCancelSubmit
        cancelLabel="Cancel"
        submitLabel={each ? "Update" : "Add User"}
        handleClose={props.handleClose}
        handleSubmit={() => formik.handleSubmit()}
      />
    </>
  );
}

export default AddUsers;
