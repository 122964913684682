import React, { useEffect, useRef, useState } from "react";
import Whitespace from "../../../Components/Molecules/Whitespace";
import ComponetTitle from "../../../Components/Organisms/ComponetTitle";
import InputField from "../../../Components/Atoms/InputField";
import PhoneNumber from "../../../Components/Molecules/Phonenumber";
import { useFormik } from "formik";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import * as Yup from "yup";
import { expression, imageFileFormat } from "../../../Utils/constants";
import userdummy from "../../../asset/user-dummy.jpg";
import CustomButton from "../../../Components/Atoms/CustomButtoncon";
import { useDispatch, useSelector } from "react-redux";
import { appUrl } from "../../../Utils/axios";
import {
  putUserRequest,
  putUserResponseClear,
} from "../../../Redux/Customer/CustomerActions";
import { getCurrentUserRequest } from "../../../Redux/LoginSaga/LoginActions";
import toast from "react-hot-toast";
export default function Profile() {
  const [checkphone, setCheckphone] = useState(0);
  const dispatch = useDispatch();

  const [image, setImage] = useState(userdummy);
  const hiddenFileInput = useRef(null);
  const handleChange = (e) => {
    const filename = e?.currentTarget?.files[0]?.name?.split(".");
    if (filename && filename[0]) {
      const format = filename[filename?.length - 1];
      if (imageFileFormat.includes(format)) {
        formik.setFieldValue("profile", e.currentTarget.files[0]);
        setImage(URL.createObjectURL(e.currentTarget.files[0]));
      }
    }
  };
  const { user } = useSelector((state) => state.authState);
  const { putUserResponse } = useSelector((state) => state.customer);
  console.log(putUserResponse);
  useEffect(() => {
    if (!putUserResponse) return;

    if (putUserResponse?.type === "success") {
      toast.success("user updated successfully");
      dispatch(getCurrentUserRequest());
    }
    dispatch(putUserResponseClear());
  }, [putUserResponse]);

  useEffect(() => {
    formik.setFieldValue("first_name", user?.data?.first_name);
    formik.setFieldValue("last_name", user?.data?.last_name);
    formik.setFieldValue("email", user?.data?.email);
    formik.setFieldValue("phone", user?.data?.phone);
    if (user?.data?.profile_picture) {
      setImage(appUrl + "/" + user?.data?.profile_picture);
    }
  }, [user]);
  const handleClick = (event) => {
    event.preventDefault();
    hiddenFileInput.current.click();
  };
  const formik = useFormik({
    initialValues: {
      first_name: "",
      last_name: "",
      profile: "",
      email: "1231@gway.com",
      phone: "",
    },
    onSubmit: (values, { setSubmitting }) => {
      setTimeout(() => {
        setSubmitting(false);
      }, 400);
      if (values.first_name === "") {
        delete values.first_name;
      }
      if (values.last_name === "") {
        delete values.last_name;
      }
      if (values.email === "") {
        delete values.email;
      }
      if (values.phone === "") {
        delete values.phone;
      }

      console.log(values, user?.data?._id);
      dispatch(putUserRequest(values, user?.data?._id));
    },

    validationSchema: Yup.object().shape({
      email: Yup.string()
        .matches(expression.email, "Invalid email")
        .matches(expression.Without_space, "Email should not contain spaces"),
      first_name: Yup.string()
        .min(3, "First Name must be at least 3 characters")
        .max(50, "First Name must be at most 50 characters")
        .matches(expression.char, "first name should only have characters")
        .matches(/^(?!\s).+(?<!\s)$/, "first name should not contain spaces"),
      last_name: Yup.string()
        .min(1, "Last Name must be at least 3 characters")
        .max(50, "Last Name must be at most 50 characters")
        .matches(expression.char, "last name should only have characters")
        .matches(/^(?!\s).+(?<!\s)$/, "last name should not contain spaces"),
      phone: Yup.string().test(
        "check phone",
        "Phone number is invalid",
        (hour) => {
          return !(hour && checkphone !== hour.length);
        }
      ),
    }),
  });
  return (
    <Whitespace>
      <>
        <ComponetTitle title="PROFILE" />
        <form onSubmit={formik.handleSubmit} className="">
          <div className="customer_file_upload">
            <div className="image_customer_container">
              {image ? (
                <img className="avatar_customer" src={image} />
              ) : (
                <AccountCircleIcon sx={{ fontSize: "101px" }} className="" />
              )}

              <input
                type="file"
                onChange={handleChange}
                ref={hiddenFileInput}
                style={{ display: "none" }}
                accept="image/jpg,image/png,image/jpeg"
              />
              <div className="customer_upload_button" onClick={handleClick}>
                Upload
              </div>
            </div>
          </div>

          <div className="col_auto_3">
            <InputField
              value={formik.values.first_name}
              onChange={formik.handleChange}
              name="first_name"
              labelText="First Name"
              placeholder="Enter First Name"
              type="text"
              onBlur={formik.handleBlur}
              required="true"
              //scrollIntoView={scrollIntoView}
              errorMsg={formik.touched.first_name && formik.errors?.first_name}
            />

            <InputField
              value={formik.values.last_name}
              onChange={formik.handleChange}
              name="last_name"
              labelText="Last Name"
              placeholder="Enter Last Name"
              required="true"
              //scrollIntoView={scrollIntoView}
              type="text"
              onBlur={formik.handleBlur}
              errorMsg={formik.touched.last_name && formik.errors?.last_name}
            />

            <InputField
              value={formik.values.email}
              onChange={formik.handleChange}
              name="email"
              labelText="Email"
              placeholder="sample@gwayerp.com"
              required="true"
              disabled={true}
              //scrollIntoView={scrollIntoView}
              type="text"
              onBlur={formik.handleBlur}
              errorMsg={formik.touched.email && formik.errors?.email}
            />

            <PhoneNumber
              value={formik.values.phone}
              onChange={formik.setFieldValue}
              labelText="Phone Number"
              name="phone"
              placeholder="Enter phone number"
              required="true"
              //scrollIntoView={scrollIntoView}
              onBlur={formik.handleBlur}
              validcheck={setCheckphone}
              errorMsg={formik.touched.phone && formik.errors?.phone}
            />
          </div>
          <div className="passoword-button">
            <CustomButton
              size="large"
              btnType={"primary"}
              btnClass={"py-10 w-90"}
              onClick={() => formik.handleSubmit}
            >
              Update
            </CustomButton>
          </div>
        </form>
      </>
    </Whitespace>
  );
}
