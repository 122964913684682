export default {
  GET_RENTED_LIST_REQUEST: "GET_RENTED_LIST_REQUEST",
  GET_RENTED_LIST_RESPONSE: "GET_RENTED_LIST_RESPONSE",

  GET_DASHBOARD_REQUEST: "GET_DASHBOARD_REQUEST",
  GET_DASHBOARD_RESPONSE: "GET_DASHBOARD_RESPONSE",
  GET_INVOICE_RESPONSE_CLEAR: "GET_INVOICE_RESPONSE_CLEAR",

  GET_INVOICE_REQUEST: "GET_INVOICE_REQUEST",
  GET_INVOICE_RESPONSE: "GET_INVOICE_RESPONSE",

  GET_RENTED_BY_ID_REQUEST: "GET_RENTED_BY_ID_REQUEST",
  GET_RENTED_BY_ID_RESPONSE: "GET_RENTED_BY_ID_RESPONSE",
  GET_RENTED_BY_ID_RESPONSE_CLEAR: "GET_RENTED_BY_ID_RESPONSE_CLEAR",

  POST_RENTED_REQUEST: "POST_RENTED_REQUEST",
  POST_RENTED_RESPONSE: "POST_RENTED_RESPONSE",
  POST_RENTED_RESPONSE_CLEAR: "POST_RENTED_RESPONSE_CLEAR",

  PUT_RENTED_REQUEST: "PUT_RENTED_REQUEST",
  PUT_RENTED_RESPONSE: "PUT_RENTED_RESPONSE",
  PUT_RENTED_RESPONSE_CLEAR: "PUT_RENTED_RESPONSE_CLEAR",

  DELETE_RENTED_REQUEST: "DELETE_RENTED_REQUEST",
  DELETE_RENTED_RESPONSE: "DELETE_RENTED_RESPONSE",
  DELETE_RENTED_RESPONSE_CLEAR: "DELETE_RENTED_RESPONSE_CLEAR",
};
