import PropTypes from "prop-types";
import PhoneInput from "react-phone-input-2";
import { useRef, useEffect } from "react";
import "./index.css";

const PhoneNumber = ({
  value,
  onChange,
  labelText,
  name,
  disabled,
  placeholder,
  onBlur,
  validcheck,
  errorMsg,
  required,
  scrollIntoView = () => {},
}) => {
  const username = name;

  const errorRef = useRef(null);

  useEffect(() => {
    if (errorRef.current) {
      scrollIntoView(errorRef.current);
    }
  });

  function findlength(country, code) {
    let count = 0;
    if (country) {
      for (let each of country) {
        if (each === ".") {
          count++;
        }
      }
    }

    return count;
  }
  return (
    <div className="input-container">
      <label className="input-label">
        {labelText}
        {required ? <span style={{ color: "red" }}>*</span> : null}
      </label>

      <PhoneInput
        country={"in"}
        disabled={disabled}
        className={
          errorMsg
            ? "input-field-password bounce_phone phone_filed"
            : "input-field-password input-normal-field-password "
        }
        value={value}
        placeholder={placeholder}
        onBlur={(phone) => onBlur(phone)}
        isValid={(value, country) => {
          let len = findlength(country?.format, country?.countryCode);
          validcheck(len);
        }}
        onChange={(phone) => {
          onChange(username, phone);
        }}
        inputProps={{
          name: username,
          required: true,
        }}
      />
      {errorMsg && (
        <p ref={errorRef} className="input-field-error-msg">
          {errorMsg}
        </p>
      )}
    </div>
  );
};

export default PhoneNumber;

PhoneNumber.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  labelText: PropTypes.string,
  name: PropTypes.string,
  disabled: PropTypes.bool,
  validcheck: PropTypes.func,
  placeholder: PropTypes.string,
  errorMsg: PropTypes.string,
  onBlur: PropTypes.func,
};
