import * as React from "react";
import Dropdown from "../../Atoms/Dropdown";
import { Pagination } from "@mui/material";

export default function CountPerPage(props) {
  const { setPagelimit, setPage, pagelimit, totalpage } = props;
  console.log(pagelimit, totalpage);
  return (
    <div>
      <div className="filter_pagi">
        <div className="sort_by_filter">
          <div className="page_input">
            <Dropdown
              value={pagelimit}
              onChange={(e) => {
                setPagelimit(e.target.value);
              }}
              name="pagelimit"
              placeholder="10"
              data_testid="pagelimit"
              required="true"
              data={[
                { name: "10", value: 10 },
                { name: "15", value: 15 },
                { name: "20", value: 20 },
                { name: "25", value: 25 },
              ]}
            />
          </div>
          <p>Per Page</p>{" "}
        </div>

        <Pagination
          count={totalpage || 1}
          variant="outlined"
          size="small"
          shape="rounded"
          onChange={(e, v) => {
            setPage(v);
          }}
        />
      </div>
    </div>
  );
}
