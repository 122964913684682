import React, { useEffect, useRef, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import "./index.css";
import { TryRounded } from "@mui/icons-material";
export default function MenuDropdown(props) {
  // const [open, setOpen] = useState(false);
  // console.log(props.index, props.clicked);
  // if (props.index === props.clicked) {
  //   console.log("clicked");
  //   set
  // }
  const ref = useRef(null);

  useEffect(() => {
    function handleClickOutside(e) {
      if (ref.current && !ref.current.contains(e.target)) {
        // alert("click outside");
        if (props.setOpen) {
          props.setOpen(false);
        }
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);

  const style = {
    width: props.width ? props.width : "10%",
    height: props.height ? props.height : "10%",
    top: props.top || "",
    left: props.left || "",
    minWidth: props.minWidth || "",
  };

  // let menuref = useRef();
  // //   useEffect(() => {
  // //     console.log(menuref);
  // //     //    let handler = () => {
  // //     //      //   if (menuref.current.contains(e.target)) {
  // //     //      setOpen(false);
  // //     //      //   console.log(menuref.current);
  // //     //    };
  // //     //    document.addEventListener("mousedown", handler);
  // //   }, [menuref]);

  // window.addEventListener("click", (e) => {
  //   console.log(e.target !== menuref.current);
  //   // if (!e.target !== menuref.current) {
  //   //   props.handleClose();
  //   // }
  // });
  return props.open ? (
    <div ref={ref} className="CustomMenu-popup">
      <div style={style} className="CustomMenu-inner">
        {props.children}
      </div>
    </div>
  ) : null;
}
