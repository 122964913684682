import RentedTypes from "./RentedActionTypes";

const initialState = {
  getRentedLoading: false,
  getRentedResponse: null,
  getDashboardLoading: false,
  getDashboardResponse: null,

  getInvoiceLoading: false,
  getInvoiceResponse: null,

  getRentedByidLoading: false,
  getRentedByidResponse: null,
  postRentedLoading: false,
  postRentedResponse: null,
  putRentedLoading: false,
  putRentedResponse: null,
  deleteRentedLoading: false,
  deleteRentedResponse: null,
};

const RentedReducer = (state = initialState, action) => {
  switch (action.type) {
    case RentedTypes.GET_RENTED_LIST_REQUEST:
      return {
        ...state,
        getRentedLoading: true,
      };
    case RentedTypes.GET_RENTED_LIST_RESPONSE:
      return {
        ...state,
        getRentedLoading: false,
        getRentedResponse: action.payload,
      };
    case RentedTypes.GET_DASHBOARD_REQUEST:
      return {
        ...state,
        getDashboardLoading: true,
      };
    case RentedTypes.GET_DASHBOARD_RESPONSE:
      return {
        ...state,
        getDashboardLoading: false,
        getDashboardResponse: action.payload,
      };
    case RentedTypes.GET_INVOICE_REQUEST:
      return {
        ...state,
        getInvoiceLoading: true,
      };
    case RentedTypes.GET_INVOICE_RESPONSE:
      return {
        ...state,
        getInvoiceLoading: false,
        getInvoiceResponse: action.payload,
      };
    case RentedTypes.GET_INVOICE_RESPONSE_CLEAR:
      return {
        ...state,

        getInvoiceResponse: null,
      };
    case RentedTypes.GET_RENTED_BY_ID_REQUEST:
      return {
        ...state,
        getRentedByidLoading: true,
      };
    case RentedTypes.GET_RENTED_BY_ID_RESPONSE:
      return {
        ...state,
        getRentedByidLoading: false,
        getRentedByidResponse: action.payload,
      };
    case RentedTypes.GET_RENTED_BY_ID_RESPONSE_CLEAR:
      return {
        ...state,
        getRentedByidResponse: null,
      };
    case RentedTypes.POST_RENTED_REQUEST:
      return {
        ...state,
        postRentedLoading: true,
      };
    case RentedTypes.POST_RENTED_RESPONSE:
      return {
        ...state,
        postRentedLoading: false,
        postRentedResponse: action.payload,
      };
    case RentedTypes.POST_RENTED_RESPONSE_CLEAR:
      return {
        ...state,
        postRentedResponse: null,
      };
    case RentedTypes.PUT_RENTED_REQUEST:
      return {
        ...state,
        putRentedLoading: true,
      };
    case RentedTypes.PUT_RENTED_RESPONSE:
      return {
        ...state,
        putRentedLoading: false,
        putRentedResponse: action.payload,
      };
    case RentedTypes.PUT_RENTED_RESPONSE_CLEAR:
      return {
        ...state,
        putRentedResponse: null,
      };
    case RentedTypes.DELETE_RENTED_REQUEST:
      return {
        ...state,
        deleteRentedLoading: true,
      };
    case RentedTypes.DELETE_RENTED_RESPONSE:
      return {
        ...state,
        deleteRentedLoading: false,
        deleteRentedResponse: action.payload,
      };
    case RentedTypes.DELETE_RENTED_RESPONSE_CLEAR:
      return {
        ...state,
        deleteRentedResponse: null,
      };
    default:
      return state;
  }
};

export default RentedReducer;
