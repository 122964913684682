import PropTypes from "prop-types";
import { useRef, useEffect } from "react";
import "./index.css";
import InputLable from "../InputLable";

const Dropdown = ({
  value,
  onChange,
  labelText,
  name,
  setValue = "value",
  setOption = "name",
  placeholder,
  errorMsg,
  required,
  onBlur,
  data_testid,
  data,
  multiple,
  disabled,
  scrollIntoView = () => {},
}) => {
  const errorRef = useRef(null);

  useEffect(() => {
    if (errorRef.current) {
      scrollIntoView(errorRef.current);
    }
  });

  return (
    <div className="dropdown-container">
      {labelText ? (
        <InputLable labelText={labelText} required={required} />
      ) : null}
      <select
        disabled={disabled}
        data-testid={data_testid}
        multiple={multiple ? true : false}
        value={value || placeholder}
        className={errorMsg ? "dropdown-input bounce" : "dropdown-input"}
        onChange={onChange}
        name={name}
        onBlur={onBlur}
      >
        <option value={placeholder} disabled>
          {placeholder}
        </option>
        {data?.map((each, index) => (
          <option key={index} value={each[setValue]}>
            {Array.isArray(setOption)
              ? `${each[setOption[0]]} - ${each[setOption[1]]}`
              : each[setOption]}
          </option>
        ))}
      </select>
      {errorMsg && (
        <p ref={errorRef} className="input-field-error-msg">
          {errorMsg}
        </p>
      )}
    </div>
  );
};

export default Dropdown;

Dropdown.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  labelText: PropTypes.string,
  name: PropTypes.string,
  setValue: PropTypes.string,
  placeholder: PropTypes.string,
  errorMsg: PropTypes.string,
  onBlur: PropTypes.func,
  data: PropTypes.array,
};
