import ActionTypes, {
  CLEAR_UNAUTH_DATA,
  UNAUTH_RESPONSE,
} from "./LoginActionTypes";

// LOGIN ACTIONS
export const loginRequest = (payload) => ({
  type: ActionTypes.LOGIN_REQUEST,
  payload,
});

export const loginResponse = (payload) => ({
  type: ActionTypes.LOGIN_RESPONSE,
  payload,
});

export const loginResClear = () => ({
  type: ActionTypes.LOGIN_RES_CLEAR,
});

// REGISTER ACTIONS
export const registerRequest = (payload) => ({
  type: ActionTypes.REGISTER_REQUEST,
  payload,
});

export const registerResponse = (payload) => ({
  type: ActionTypes.REGISTER_RESPONSE,
  payload,
});

export const registerResClear = () => ({
  type: ActionTypes.REGISTER_RES_CLEAR,
});

// LOGOUT ACTIONS
export const logoutRequest = (payload) => ({
  type: ActionTypes.LOGOUT_REQUEST,
  payload,
});

export const logoutResponse = (payload) => ({
  type: ActionTypes.LOGOUT_RESPONSE,
  payload,
});

export const logoutResClear = () => ({
  type: ActionTypes.LOGOUT_RES_CLEAR,
});

// CLEAR USER DATA AFTER LOGOUT
export const clearUserData = () => ({
  type: ActionTypes.CLEAR_USER_DATA,
});

// GET CURRENT USER ACTIONS
export const getCurrentUserRequest = (payload) => ({
  type: ActionTypes.GET_CURRENT_USER_REQUEST,
  payload,
});

export const getCurrentUserResponse = (payload) => ({
  type: ActionTypes.GET_CURRENT_USER_RESPONSE,
  payload,
});

// FORGOT PASSWORD ACTIONS
export const forgotPasswordRequest = (payload) => ({
  type: ActionTypes.FORGOT_PASSWORD_REQUEST,
  payload,
});

export const forgotPasswordResponse = (payload) => ({
  type: ActionTypes.FORGOT_PASSWORD_RESPONSE,
  payload,
});

export const forgotPasswordResClear = () => ({
  type: ActionTypes.FORGOT_PASSWORD_RES_CLEAR,
});

// RESET PASSWORD ACTIONS
export const resetPasswordRequest = (payload) => ({
  type: ActionTypes.RESET_PASSWORD_REQUEST,
  payload,
});

export const resetPasswordResponse = (payload) => ({
  type: ActionTypes.RESET_PASSWORD_RESPONSE,
  payload,
});

export const resetPasswordResClear = () => ({
  type: ActionTypes.RESET_PASSWORD_RES_CLEAR,
});

// CHANGE PASSWORD ACTIONS
export const changePasswordRequest = (payload, id) => ({
  type: ActionTypes.CHANGE_PASSWORD_REQUEST,
  payload,
  id,
});

export const changePasswordResponse = (payload) => ({
  type: ActionTypes.CHANGE_PASSWORD_RESPONSE,
  payload,
});

export const changePasswordResClear = () => ({
  type: ActionTypes.CHANGE_PASSWORD_RES_CLEAR,
});

// UNAUTHORISED DATA
export const unAuthorizedData = (payload) => ({
  type: UNAUTH_RESPONSE,
  payload,
});

export const clearUnAuthData = () => ({
  type: CLEAR_UNAUTH_DATA,
});
