import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import {
  clearUnAuthData,
  logoutRequest,
} from "../Redux/LoginSaga/LoginActions";
import toast from "react-hot-toast";
import { CircularProgress } from "@mui/material";

function ProtectedRoute() {
  const {
    user: userData,
    userLoading,
    unAuthorized,
  } = useSelector((state) => state.authState);
  const location = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!unAuthorized) return;
    console.log(unAuthorized);
    if (unAuthorized?.response?.data?.message) {
      if (unAuthorized?.response?.data?.message === "Token required") {
        // Do nothing if no token is provided
      } else if (
        unAuthorized?.response?.data?.message ===
        "You have logged-in in max number of 2 - devices, Login again"
      ) {
        alert(unAuthorized?.response?.data?.message);
        localStorage.clear();
        window.location.reload();
      } else if (unAuthorized?.response?.data?.message === "Invalid Token") {
        toast.error("Session Expired!");
        //handle logout here
        setTimeout(() => {
          dispatch(logoutRequest({ token: localStorage.getItem("token") }));
          localStorage.clear();
          window.location.reload();
        }, 3000);
      } else if (
        unAuthorized?.response?.data?.message ===
        "Your account has been deleted"
      ) {
        toast.error(unAuthorized?.response?.data?.message);
        setTimeout(() => {
          localStorage.clear();
          window.location.reload();
        }, 3000);
      } else if (
        unAuthorized?.response?.data?.message === "Authentication Failed"
      ) {
        toast.error(unAuthorized?.response?.data?.message);
        setTimeout(() => {
          localStorage.clear();
          window.location.reload();
        }, 3000);
      } else {
        toast.error(unAuthorized?.response?.data?.message);
      }
    } else {
      toast.error(
        unAuthorized?.response?.data?.message || "Something went wrong!"
      );
    }
    dispatch(clearUnAuthData());
  }, [unAuthorized]);

  if (userLoading && !userData) {
    return (
      <div className="circular_loader_container" style={{ height: "100vh" }}>
        <CircularProgress className="circular_loader" />
        Loading...
      </div>
    );
  }
  return Boolean(userData) ? (
    <Outlet />
  ) : (
    <Navigate to="/login" state={{ from: location }} replace />
  );
}

export default ProtectedRoute;
