import { Toaster } from "react-hot-toast";
import { HelmetProvider } from "react-helmet-async";
import { setAuthToken } from "./Utils/axios";
import { useEffect } from "react";
import "react-phone-input-2/lib/style.css";
import Router from "./Router/Index";
import "./App.css";

function App() {
  const token = localStorage.getItem("jwt");

  useEffect(() => {
    setAuthToken(token);
  }, [token]);
  return (
    <div className="App">
      <Toaster
        position="top-center"
        toastOptions={{
          duration: 1500,
        }}
      />
      <HelmetProvider>
        <Router />
      </HelmetProvider>
    </div>
  );
}

export default App;
