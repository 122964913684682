import RentedTypes from "./RentedActionTypes";

//----------------team list GET--------------------
export const getRentedListRequest = (payload) => ({
  type: RentedTypes.GET_RENTED_LIST_REQUEST,
  payload: payload,
});

export const getRentedListResponse = (payload) => ({
  type: RentedTypes.GET_RENTED_LIST_RESPONSE,
  payload: payload,
});
//----------------Dash list GET--------------------

export const getDashboardRequest = () => ({
  type: RentedTypes.GET_DASHBOARD_REQUEST,
});

export const getDashboardResponse = (payload) => ({
  type: RentedTypes.GET_DASHBOARD_RESPONSE,
  payload: payload,
});
export const getInvoiceRequest = (id) => ({
  type: RentedTypes.GET_INVOICE_REQUEST,
  id,
});

export const getInvoiceResponse = (payload) => ({
  type: RentedTypes.GET_INVOICE_RESPONSE,
  payload: payload,
});
export const getInvoiceResponseclear = () => ({
  type: RentedTypes.GET_INVOICE_RESPONSE_CLEAR,
});
export const getRentedByIdRequest = (id) => ({
  type: RentedTypes.GET_RENTED_BY_ID_REQUEST,
  id: id,
});

export const getRentedByIdResponse = (payload) => ({
  type: RentedTypes.GET_RENTED_BY_ID_RESPONSE,
  payload: payload,
});

export const getRentedByIdResponseClear = () => ({
  type: RentedTypes.GET_RENTED_BY_ID_RESPONSE_CLEAR,
});
//----------------team POST--------------------------
export const postRentedRequest = (payload) => ({
  type: RentedTypes.POST_RENTED_REQUEST,
  payload: payload,
});

export const postRentedResponse = (payload) => ({
  type: RentedTypes.POST_RENTED_RESPONSE,
  payload: payload,
});

export const postRentedResponseClear = () => ({
  type: RentedTypes.POST_RENTED_RESPONSE_CLEAR,
});

//----------------team PUT----------------------------
export const putRentedRequest = (payload, id) => ({
  type: RentedTypes.PUT_RENTED_REQUEST,
  payload: payload,
  id: id,
});

export const putRentedResponse = (payload) => ({
  type: RentedTypes.PUT_RENTED_RESPONSE,
  payload: payload,
});

export const putRentedResponseClear = () => ({
  type: RentedTypes.PUT_RENTED_RESPONSE_CLEAR,
});

//--------------team DELETE----------------------------
export const deleteRentedRequest = (id) => ({
  type: RentedTypes.DELETE_RENTED_REQUEST,
  id: id,
});

export const deleteRentedResponse = (payload) => ({
  type: RentedTypes.DELETE_RENTED_RESPONSE,
  payload: payload,
});

export const deleteRentedResponseClear = () => ({
  type: RentedTypes.DELETE_RENTED_RESPONSE_CLEAR,
});
