import AdminUserTypes from "./AdminUserActionTypes";

const initialState = {
  getAdminUserLoading: false,
  getAdminUserResponse: null,
  getAdminUserByidLoading: false,
  getAdminUserByidResponse: null,
  postAdminUserLoading: false,
  postAdminUserResponse: null,
  putAdminUserLoading: false,
  putAdminUserResponse: null,
  deleteAdminUserLoading: false,
  deleteAdminUserResponse: null,
  putUserLoading: false,
  putUserResponse: null,
};

const AdminUserReducer = (state = initialState, action) => {
  switch (action.type) {
    case AdminUserTypes.GET_ADMINUSER_LIST_REQUEST:
      return {
        ...state,
        getAdminUserLoading: true,
      };
    case AdminUserTypes.GET_ADMINUSER_LIST_RESPONSE:
      return {
        ...state,
        getAdminUserLoading: false,
        getAdminUserResponse: action.payload,
      };
    case AdminUserTypes.GET_ADMINUSER_BY_ID_REQUEST:
      return {
        ...state,
        getAdminUserByidLoading: true,
      };
    case AdminUserTypes.GET_ADMINUSER_BY_ID_RESPONSE:
      return {
        ...state,
        getAdminUserByidLoading: false,
        getAdminUserByidResponse: action.payload,
      };
    case AdminUserTypes.GET_ADMINUSER_BY_ID_RESPONSE_CLEAR:
      return {
        ...state,
        getAdminUserByidResponse: null,
      };
    case AdminUserTypes.POST_ADMINUSER_REQUEST:
      return {
        ...state,
        postAdminUserLoading: true,
      };
    case AdminUserTypes.POST_ADMINUSER_RESPONSE:
      return {
        ...state,
        postAdminUserLoading: false,
        postAdminUserResponse: action.payload,
      };
    case AdminUserTypes.POST_ADMINUSER_RESPONSE_CLEAR:
      return {
        ...state,
        postAdminUserResponse: null,
      };
    case AdminUserTypes.PUT_ADMINUSER_REQUEST:
      return {
        ...state,
        putAdminUserLoading: true,
      };
    case AdminUserTypes.PUT_ADMINUSER_RESPONSE:
      return {
        ...state,
        putAdminUserLoading: false,
        putAdminUserResponse: action.payload,
      };
    case AdminUserTypes.PUT_ADMINUSER_RESPONSE_CLEAR:
      return {
        ...state,
        putAdminUserResponse: null,
      };
    case AdminUserTypes.DELETE_ADMINUSER_REQUEST:
      return {
        ...state,
        deleteAdminUserLoading: true,
      };
    case AdminUserTypes.DELETE_ADMINUSER_RESPONSE:
      return {
        ...state,
        deleteAdminUserLoading: false,
        deleteAdminUserResponse: action.payload,
      };
    case AdminUserTypes.DELETE_ADMINUSER_RESPONSE_CLEAR:
      return {
        ...state,
        deleteAdminUserResponse: null,
      };
    case AdminUserTypes.PUT_USER_REQUEST:
      return {
        ...state,
        putUserLoading: true,
      };
    case AdminUserTypes.PUT_USER_RESPONSE:
      return {
        ...state,
        putUserLoading: false,
        putUserResponse: action.payload,
      };
    case AdminUserTypes.PUT_USER_RESPONSE_CLEAR:
      return {
        ...state,
        putUserResponse: null,
      };
    default:
      return state;
  }
};

export default AdminUserReducer;
