import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { useState, useEffect } from "react";

import toast from "react-hot-toast";
import * as Yup from "yup";
import { gwayloginlogo } from "../../../Utils/Utils";
// import "./index.css";
import { useLocation, useNavigate } from "react-router-dom";

import HelmetData from "../../../Utils/HelmetData";
import InputField from "../../../Components/Atoms/InputField";
import CustomButton from "../../../Components/Atoms/CustomButtoncon";
import {
  resetPasswordRequest,
  resetPasswordResClear,
} from "../../../Redux/LoginSaga/LoginActions";

// import CustomButton from "../../components/CustomButton";

const ResetPassword = () => {
  const query = new URLSearchParams(window.location.search);
  const id = query.get("id");
  const token = query.get("token");

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { resetPasswordResponse } = useSelector((state) => state.authState);

  const formik = useFormik({
    initialValues: { password: "", confirm_password: "" },
    onSubmit: (values) => {
      dispatch(resetPasswordRequest({ ...values, id: id, token: token }));
    },

    validationSchema: Yup.object().shape({
      password: Yup.string()
        .required("Required")
        .matches(
          /^\s*\S[\s\S]*$/g,
          "Password field cannot contain only blankspaces"
        )

        .matches(/[a-z]/, "Password must have atleast 1 small letter.")
        .matches(/[A-Z]/, "Password must have atleast 1 capital letter.")
        .matches(
          /^(?=.*\d)(?=.*[!@#$%^&*])/,
          "Password must have atleast 1 num & 1 special letter."
        )
        .matches(/^.{8,}$/, "Minimum of 8 characters"),

      confirm_password: Yup.string()
        .required("Required")
        .matches(
          /^\s*\S[\s\S]*$/g,
          "Confirm password this field cannot contain only blankspaces"
        )
        .oneOf([Yup.ref("password"), ""], "Password must match"),
    }),
  });

  useEffect(() => {
    if (!resetPasswordResponse) return;
    if (resetPasswordResponse?.type === "success") {
      toast.success("Reset Successfully");
      navigate("/login");
    } else {
      toast.error(resetPasswordResponse?.response?.data?.message);
    }
    return () => {
      dispatch(resetPasswordResClear());
    };
  }, [resetPasswordResponse]);

  return (
    <>
      <HelmetData title="Login" />
      <div className="login-page-container">
        <form onSubmit={formik.handleSubmit} className="login-form">
          <img
            className="login-form-logo"
            src={gwayloginlogo}
            alt="gway-logo"
          />
          {/* <div className="login-heading-container"> */}
          <h1 className="login-heading">Reset Password</h1>
          {/* </div> */}
          <div className="forgot-input-field-container">
            <InputField
              value={formik.values.password}
              onChange={formik.handleChange}
              name="password"
              labelText="Password"
              placeholder="Enter your Password"
              required="true"
              onBlur={formik.handleBlur}
              errorMsg={formik.touched.password && formik.errors?.password}
              type="password"
            />
            <InputField
              value={formik.values.confirm_password}
              onChange={formik.handleChange}
              name="confirm_password"
              labelText="Confirm Password"
              placeholder="Enter Confirm Password"
              required="true"
              onBlur={formik.handleBlur}
              errorMsg={
                formik.touched.confirm_password &&
                formik.errors?.confirm_password
              }
              type="password"
            />
          </div>

          <div className="login_button">
            <CustomButton type="submit" btnType="primary" size="large">
              Change Password
            </CustomButton>
          </div>
        </form>
        <div className="sideimage_container">
          <div className="grand_color"></div>
        </div>
      </div>
    </>
  );
};

export default ResetPassword;
