import { takeLatest, put, call } from "redux-saga/effects";
import UsersTypes from "./UsersActionTypes";
import {
  deleteUsersResponse,
  getUsersByIdResponse,
  getUsersListResponse,
  postUsersResponse,
  putUsersResponse,
  putUserRequest,
  putUserResponse,
} from "./UsersActions";
import { unAuthorizedData } from "../LoginSaga/LoginActions";
import { axiosDelete, axiosGet, axiosPost, axiosPut } from "../../Utils/axios";

export function* onGetUsersList({ payload }) {
  try {
    const {
      company_id = "",
      filter,
      quickFilter = "",
      keyword = "",
      count = "",
    } = payload;

    const sort = filter.sortBy.split("-");

    const url = `user?page=${
      filter.page
    }&company_id=${company_id}&filter=${keyword}&role=${quickFilter}&sort_by=${
      sort[0] ? sort[0] : ""
    }&sort=${sort[1] ? sort[1] : ""}&count=${count}`;

    const response = yield call(() => axiosGet(url).then((res) => res.data));
    yield put(getUsersListResponse(response));
  } catch (error) {
    yield put(unAuthorizedData(error));
  }
}

export function* onGetByIdUsers({ id }) {
  try {
    const url = `user/${id}`;
    const response = yield call(() => axiosGet(url).then((res) => res.data));
    yield put(getUsersByIdResponse(response));
  } catch (error) {
    yield put(unAuthorizedData(error));
  }
}

export function* onPostUsers({ payload }) {
  try {
    const url = `user`;
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };
    const response = yield call(() =>
      axiosPost(url, payload, config).then((res) => res.data)
    );
    yield put(postUsersResponse(response));
  } catch (error) {
    yield put(unAuthorizedData(error));
  }
}

export function* onPutUsers({ payload, id }) {
  try {
    const url = `user/${id}`;
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };
    const response = yield call(() =>
      axiosPut(url, payload, config).then((res) => res.data)
    );
    yield put(putUsersResponse(response));
  } catch (error) {
    yield put(unAuthorizedData(error));
  }
}

export function* onDeleteUsers({ id }) {
  try {
    const url = `user/${id}`;

    const response = yield call(() => axiosDelete(url).then((res) => res.data));
    yield put(deleteUsersResponse(response));
  } catch (error) {
    yield put(unAuthorizedData(error));
  }
}

export function* UsersWatcherSaga() {
  yield takeLatest(UsersTypes.GET_USERS_LIST_REQUEST, onGetUsersList);
  yield takeLatest(UsersTypes.GET_USERS_BY_ID_REQUEST, onGetByIdUsers);
  yield takeLatest(UsersTypes.POST_USERS_REQUEST, onPostUsers);
  yield takeLatest(UsersTypes.PUT_USERS_REQUEST, onPutUsers);

  yield takeLatest(UsersTypes.DELETE_USERS_REQUEST, onDeleteUsers);
}
