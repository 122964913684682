export default {
  GET_COMPANIES_LIST_REQUEST: "GET_COMPANIES_LIST_REQUEST",
  GET_COMPANIES_LIST_RESPONSE: "GET_COMPANIES_LIST_RESPONSE",

  GET_COMPANIES_BY_ID_REQUEST: "GET_COMPANIES_BY_ID_REQUEST",
  GET_COMPANIES_BY_ID_RESPONSE: "GET_COMPANIES_BY_ID_RESPONSE",
  GET_COMPANIES_BY_ID_RESPONSE_CLEAR: "GET_COMPANIES_BY_ID_RESPONSE_CLEAR",

  POST_COMPANIES_REQUEST: "POST_COMPANIES_REQUEST",
  POST_COMPANIES_RESPONSE: "POST_COMPANIES_RESPONSE",
  POST_COMPANIES_RESPONSE_CLEAR: "POST_COMPANIES_RESPONSE_CLEAR",

  PUT_COMPANIES_REQUEST: "PUT_COMPANIES_REQUEST",
  PUT_COMPANIES_RESPONSE: "PUT_COMPANIES_RESPONSE",
  PUT_COMPANIES_RESPONSE_CLEAR: "PUT_COMPANIES_RESPONSE_CLEAR",

  PUT_USER_REQUEST: "PUT_USER_REQUEST",
  PUT_USER_RESPONSE: "PUT_USER_RESPONSE",
  PUT_USER_RESPONSE_CLEAR: "PUT_USER_RESPONSE_CLEAR",

  DELETE_COMPANIES_REQUEST: "DELETE_COMPANIES_REQUEST",
  DELETE_COMPANIES_RESPONSE: "DELETE_COMPANIES_RESPONSE",
  DELETE_COMPANIES_RESPONSE_CLEAR: "DELETE_COMPANIES_RESPONSE_CLEAR",
};
