import EditIcon from "@mui/icons-material/Edit";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import DeleteIcon from "@mui/icons-material/Delete";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import DoneIcon from "@mui/icons-material/Done";
import { QrCode } from "@mui/icons-material";
import FormatAlignJustifyIcon from "@mui/icons-material/FormatAlignJustify";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import CloseIcon from "@mui/icons-material/Close";
import AddIcon from "@mui/icons-material/Add";
import HistoryToggleOffIcon from "@mui/icons-material/HistoryToggleOff";
import "./index.css";
import CustomButton from "../../Atoms/CustomButtoncon";
import { TableIconConstant } from "../../../Utils/constants";

const TableButton = ({
  buttonType,
  size,
  statusVal = "",
  onClickBtn = (e) => {},
  disable = "",
  lableText,
}) => {
  const renderBtn = () => {
    switch (buttonType) {
      case TableIconConstant.view:
        return <RemoveRedEyeIcon />;
      case TableIconConstant.edit:
        return <EditIcon />;
      case TableIconConstant.addUser:
        return <PersonAddIcon />;
      case TableIconConstant.status:
        return statusVal ? <RemoveCircleOutlineIcon /> : <DoneIcon />;
      case TableIconConstant.time:
        return <AccessTimeIcon />;
      case TableIconConstant.delete:
        return <DeleteIcon />;
      case TableIconConstant.copy:
        return <ContentCopyIcon />;
      case TableIconConstant.qrcode:
        return <QrCode className="employee-action-btn-icon" />;
      case TableIconConstant.barcode:
        return (
          <FormatAlignJustifyIcon style={{ transform: "rotate(90deg)" }} />
        );
      case TableIconConstant.location:
        return <LocationOnOutlinedIcon />;
      case TableIconConstant.close:
        return <CloseIcon />;
      case TableIconConstant.history:
        return <HistoryToggleOffIcon />;
      case TableIconConstant.add:
        return <AddIcon />;
    }
  };

  return (
    <div className={` ${size === "small" ? "button_small" : "button_table"}`}>
      <CustomButton
        size="small"
        btnType="secondary"
        onClick={(e) => {
          onClickBtn(e);
        }}
        data-testid={buttonType}
      >
        {renderBtn()}
      </CustomButton>
    </div>
  );
};
export default TableButton;
