import React, { useState } from "react";
import CustomButton from "../../Atoms/CustomButtoncon";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import "./index.css";

import MenuIcon from "@mui/icons-material/Menu";
import Model from "../ModelComponent";
import { useLocation, useNavigate } from "react-router-dom";
import MenuDropdown from "../MenuDropdown";
import { appUrl, setAuthToken } from "../../../Utils/axios";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { getCurrentUserRequest } from "../../../Redux/LoginSaga/LoginActions";
import { Modal } from "@mui/material";
import Sidenav from "../Sidenav/Sidenav";
function Header(props) {
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [modalOpen, setModalOpen] = useState(false);
  const handleModalOpen = () => setModalOpen(true);
  const handleModalClose = () => {
    setModalOpen(false);
  };

  const [menuOpen, setMenuOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
  };
  const logoutUser = () => {
    localStorage.removeItem("jwt");
    setAuthToken();
    dispatch(getCurrentUserRequest());
    toast.success("Logout Successfully");
  };
  const location = useLocation();
  const currentTab = location.pathname;
  const page =
    currentTab === "/adminuser"
      ? "Admin User"
      : currentTab === "/companies"
      ? "Companies"
      : currentTab === "/myprofile"
      ? "My Profile"
      : "DashBoard";
  const { user } = useSelector((state) => state.authState);

  return (
    <>
      <Modal
        open={modalOpen}
        onClose={handleModalClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        {/* <Box style={{ overflowX: "hidden" }} sx={style}> */}
        <Sidenav header={true} />
        {/* </Box> */}
      </Modal>

      <Model
        width={"100%"}
        height={"100%"}
        maxHeight={"100%"}
        header={"rentHeader"}
        handleClose={handleClose}
        open={open}
        Title={"Rent Material"}
      >
        {/* <RentFrom handleClose={handleClose} /> */}
      </Model>

      <div className="header-container">
        <div className="header-ham-search-container">
          {" "}
          <MenuIcon
            onClick={() => handleModalOpen()}
            className="header-hamburger-icon"
          />
          <div className="header-name-para-container">
            <p className="header-para1">
              Welcome!
              <span>
                {" "}
                {user?.data?.first_name} {user?.data?.last_name}
              </span>
            </p>
            <p className="header-para2">{page}</p>
          </div>
        </div>
        <div className="header-profile-notification-container">
          <div className="header-notification-icon-container">
            {/* <CustomButton
              size="large"
              btnType={"primary"}
              btnClass={"py-10"}
              onClick={() => {
                handleOpen();
              }}
            >
              Rent Material
            </CustomButton> */}
          </div>
          {/*             onClick={() => {
                        handlejobcheckstatus(
                          getJobsByIdResponse?.data?.check_in
                        );
                      }}
                      className={
                        getJobsByIdResponse?.data?.check_in
                          ? "jobs-table-checkin-btn"
                          : "jobs-table-checkout-btn"
                      }
                    >
                      {getJobsByIdResponse?.data?.check_in
                        ? " Check in"
                        : " Check out"} */}
          {/* <div className="header-notification-icon-container">
            <NotificationsIcon
              sx={{ fontSize: "22px" }}
              className="notification-icon"
            />
            <div className="notification-count-container">
              <p className="notification-count-para">2</p>
            </div>
          </div> */}

          <div
            id="basic-button"
            onClick={() => {
              "";
              setMenuOpen(true);
            }}
            onKeyDown={() => {}}
            className="header-name-avatar-container"
          >
            <div className="header-name-para-container">
              {/* <p className="header-para1">Hi</p> */}
              {/* <p className="header-para2">{capitalize(fullName)}</p> */}
            </div>
            <div className="profile-img-container">
              {user?.data?.profile_picture ? (
                <img
                  src={appUrl + "/" + user?.data?.profile_picture}
                  alt="user_profile"
                  className="user_profile"
                />
              ) : (
                <AccountCircleIcon
                  sx={{ fontSize: "44px" }}
                  className="profile-avatar"
                />
              )}
            </div>
          </div>
          <MenuDropdown
            top="25px"
            left="-55px"
            minWidth="100px"
            width="auto"
            open={menuOpen}
            setOpen={setMenuOpen}
          >
            <div className="header-menu-container">
              <p
                className="header-menu-para "
                onClick={() => navigate("/myprofile")}
              >
                My Profile
              </p>
              <p
                className="header-menu-para "
                onClick={() => {
                  //getUserLocation();
                  // handleLogoutOpen();
                  logoutUser();
                }}
              >
                Logout
              </p>
            </div>
          </MenuDropdown>
        </div>
      </div>
    </>
  );
}

export default Header;
