export default {
  GET_USERS_LIST_REQUEST: "GET_USERS_LIST_REQUEST",
  GET_USERS_LIST_RESPONSE: "GET_USERS_LIST_RESPONSE",

  GET_USERS_BY_ID_REQUEST: "GET_USERS_BY_ID_REQUEST",
  GET_USERS_BY_ID_RESPONSE: "GET_USERS_BY_ID_RESPONSE",
  GET_USERS_BY_ID_RESPONSE_CLEAR: "GET_USERS_BY_ID_RESPONSE_CLEAR",

  POST_USERS_REQUEST: "POST_USERS_REQUEST",
  POST_USERS_RESPONSE: "POST_USERS_RESPONSE",
  POST_USERS_RESPONSE_CLEAR: "POST_USERS_RESPONSE_CLEAR",

  PUT_USERS_REQUEST: "PUT_USERS_REQUEST",
  PUT_USERS_RESPONSE: "PUT_USERS_RESPONSE",
  PUT_USERS_RESPONSE_CLEAR: "PUT_USERS_RESPONSE_CLEAR",

  PUT_USER_REQUEST: "PUT_USER_REQUEST",
  PUT_USER_RESPONSE: "PUT_USER_RESPONSE",
  PUT_USER_RESPONSE_CLEAR: "PUT_USER_RESPONSE_CLEAR",

  DELETE_USERS_REQUEST: "DELETE_USERS_REQUEST",
  DELETE_USERS_RESPONSE: "DELETE_USERS_RESPONSE",
  DELETE_USERS_RESPONSE_CLEAR: "DELETE_USERS_RESPONSE_CLEAR",
};
