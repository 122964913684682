import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { useState, useEffect } from "react";

import toast from "react-hot-toast";
import * as Yup from "yup";
import { gwayloginlogo } from "../../../Utils/Utils";
// import "./index.css";
import { useLocation, useNavigate } from "react-router-dom";

import HelmetData from "../../../Utils/HelmetData";
import InputField from "../../../Components/Atoms/InputField";
import CustomButton from "../../../Components/Atoms/CustomButtoncon";
import {
  forgotPasswordRequest,
  forgotPasswordResClear,
  getCurrentUserRequest,
  loginRequest,
  loginResClear,
} from "../../../Redux/LoginSaga/LoginActions";

// import CustomButton from "../../components/CustomButton";

const ForgotPassword = () => {
  const [savePassword, setSavePassword] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const { forgotPasswordResponse } = useSelector((state) => state.authState);

  const formik = useFormik({
    initialValues: { email: "" },
    onSubmit: (values) => {
      dispatch(forgotPasswordRequest(values));
    },

    validationSchema: Yup.object().shape({
      email: Yup.string().required("Required"),
    }),
  });

  useEffect(() => {
    if (!forgotPasswordResponse) return;
    if (forgotPasswordResponse?.type === "success") {
      toast.success("Mail Sent Successfully");
      navigate("/login");
    } else {
      toast.error(forgotPasswordResponse?.response?.data?.message);
    }
    dispatch(forgotPasswordResClear());
  }, [forgotPasswordResponse]);

  return (
    <>
      <HelmetData title="Login" />
      <div className="login-page-container">
        <form onSubmit={formik.handleSubmit} className="login-form">
          <img
            className="login-form-logo"
            src={gwayloginlogo}
            alt="gway-logo"
          />
          {/* <div className="login-heading-container"> */}
          <h1 className="login-heading">Forgot Password</h1>
          {/* </div> */}
          <div className="forgot-input-field-container">
            <InputField
              value={formik.values.email}
              onChange={formik.handleChange}
              name="email"
              labelText="Email"
              placeholder="Enter your email"
              required="true"
              onBlur={formik.handleBlur}
              errorMsg={formik.touched.email && formik.errors?.email}
              type="email"
            />
          </div>

          <div className="login_button">
            <CustomButton type="submit" btnType="primary" size="large">
              Send Email
            </CustomButton>
          </div>
        </form>
        <div className="sideimage_container">
          <div className="grand_color"></div>
        </div>
      </div>
    </>
  );
};

export default ForgotPassword;
