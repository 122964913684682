import UsersTypes from "./UsersActionTypes";

const initialState = {
  getUsersLoading: false,
  getUsersResponse: null,
  getUsersByidLoading: false,
  getUsersByidResponse: null,
  postUsersLoading: false,
  postUsersResponse: null,
  putUsersLoading: false,
  putUsersResponse: null,
  deleteUsersLoading: false,
  deleteUsersResponse: null,
  putUserLoading: false,
  putUserResponse: null,
};

const UsersReducer = (state = initialState, action) => {
  switch (action.type) {
    case UsersTypes.GET_USERS_LIST_REQUEST:
      return {
        ...state,
        getUsersLoading: true,
      };
    case UsersTypes.GET_USERS_LIST_RESPONSE:
      return {
        ...state,
        getUsersLoading: false,
        getUsersResponse: action.payload,
      };
    case UsersTypes.GET_USERS_BY_ID_REQUEST:
      return {
        ...state,
        getUsersByidLoading: true,
      };
    case UsersTypes.GET_USERS_BY_ID_RESPONSE:
      return {
        ...state,
        getUsersByidLoading: false,
        getUsersByidResponse: action.payload,
      };
    case UsersTypes.GET_USERS_BY_ID_RESPONSE_CLEAR:
      return {
        ...state,
        getUsersByidResponse: null,
      };
    case UsersTypes.POST_USERS_REQUEST:
      return {
        ...state,
        postUsersLoading: true,
      };
    case UsersTypes.POST_USERS_RESPONSE:
      return {
        ...state,
        postUsersLoading: false,
        postUsersResponse: action.payload,
      };
    case UsersTypes.POST_USERS_RESPONSE_CLEAR:
      return {
        ...state,
        postUsersResponse: null,
      };
    case UsersTypes.PUT_USERS_REQUEST:
      return {
        ...state,
        putUsersLoading: true,
      };
    case UsersTypes.PUT_USERS_RESPONSE:
      return {
        ...state,
        putUsersLoading: false,
        putUsersResponse: action.payload,
      };
    case UsersTypes.PUT_USERS_RESPONSE_CLEAR:
      return {
        ...state,
        putUsersResponse: null,
      };
    case UsersTypes.DELETE_USERS_REQUEST:
      return {
        ...state,
        deleteUsersLoading: true,
      };
    case UsersTypes.DELETE_USERS_RESPONSE:
      return {
        ...state,
        deleteUsersLoading: false,
        deleteUsersResponse: action.payload,
      };
    case UsersTypes.DELETE_USERS_RESPONSE_CLEAR:
      return {
        ...state,
        deleteUsersResponse: null,
      };
    case UsersTypes.PUT_USER_REQUEST:
      return {
        ...state,
        putUserLoading: true,
      };
    case UsersTypes.PUT_USER_RESPONSE:
      return {
        ...state,
        putUserLoading: false,
        putUserResponse: action.payload,
      };
    case UsersTypes.PUT_USER_RESPONSE_CLEAR:
      return {
        ...state,
        putUserResponse: null,
      };
    default:
      return state;
  }
};

export default UsersReducer;
