import DashboardIcon from "@mui/icons-material/Dashboard";
import GroupsIcon from "@mui/icons-material/Groups";

import { gwayloginlogo } from "../../../Utils/Utils";
import { useNavigate } from "react-router-dom";

import "./index.css";

import { useSelector } from "react-redux";
import { Category, Inventory } from "@mui/icons-material";

const Sidenav = ({ header = false }) => {
  const currentTab = window.location.href.split("/")[3];

  //console.log(window.location.pathname.includes("/employee"))

  const selectedClass = "sidebar-menu-item sidebar-menu-selected";

  const navigate = useNavigate();
  // const { getUserResponse } = useSelector((state) => state.getUserReducer);
  // const permission = getUserResponse?.data?.user?.permission?.reference_keys;
  console.log(header);
  return (
    <div
      className={` ${
        header
          ? "sidebar-container sidebar-header-container"
          : "sidebar-container"
      }`}
    >
      <div className="sidebar-logo-img">
        <img
          onClick={() => navigate("/")}
          // className="sidebar-logo-img"
          src={gwayloginlogo}
          alt="gway-logo"
        />
      </div>
      <div className="sidebar-menu-container">
        <div
          onClick={() => navigate("/")}
          onKeyDown={() => {}}
          className={!currentTab ? selectedClass : "sidebar-menu-item"}
        >
          <DashboardIcon
            sx={{ fontSize: "17px" }}
            className="sidebar-menu-item-icon"
          />
          <p className="sidebar-menu-item-para">Dashboard</p>
        </div>

        <div
          onClick={() => navigate("/companies")}
          onKeyDown={() => {}}
          className={
            currentTab === "materials" ? selectedClass : "sidebar-menu-item"
          }
        >
          <Inventory
            sx={{ fontSize: "17px" }}
            className="sidebar-menu-item-icon"
          />
          <p className="sidebar-menu-item-para">Companies</p>
        </div>
        <div
          onClick={() => navigate("/adminuser")}
          onKeyDown={() => {}}
          className={
            currentTab === "material_category"
              ? selectedClass
              : "sidebar-menu-item"
          }
        >
          <Category
            sx={{ fontSize: "17px" }}
            className="sidebar-menu-item-icon"
          />
          <p className="sidebar-menu-item-para">Admin Users</p>
        </div>
      </div>
    </div>
  );
};
export default Sidenav;
