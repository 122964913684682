import CompaniesTypes from "./CompaniesActionTypes";

//----------------team list GET--------------------
export const getCompaniesListRequest = (payload) => ({
  type: CompaniesTypes.GET_COMPANIES_LIST_REQUEST,
  payload: payload,
});

export const getCompaniesListResponse = (payload) => ({
  type: CompaniesTypes.GET_COMPANIES_LIST_RESPONSE,
  payload: payload,
});

export const getCompaniesByIdRequest = (id) => ({
  type: CompaniesTypes.GET_COMPANIES_BY_ID_REQUEST,
  id: id,
});

export const getCompaniesByIdResponse = (payload) => ({
  type: CompaniesTypes.GET_COMPANIES_BY_ID_RESPONSE,
  payload: payload,
});

export const getCompaniesByIdResponseClear = () => ({
  type: CompaniesTypes.GET_COMPANIES_BY_ID_RESPONSE_CLEAR,
});
//----------------team POST--------------------------
export const postCompaniesRequest = (payload) => ({
  type: CompaniesTypes.POST_COMPANIES_REQUEST,
  payload: payload,
});

export const postCompaniesResponse = (payload) => ({
  type: CompaniesTypes.POST_COMPANIES_RESPONSE,
  payload: payload,
});

export const postCompaniesResponseClear = () => ({
  type: CompaniesTypes.POST_COMPANIES_RESPONSE_CLEAR,
});

//----------------team PUT----------------------------
export const putCompaniesRequest = (payload, id) => ({
  type: CompaniesTypes.PUT_COMPANIES_REQUEST,
  payload: payload,
  id: id,
});

export const putCompaniesResponse = (payload) => ({
  type: CompaniesTypes.PUT_COMPANIES_RESPONSE,
  payload: payload,
});

export const putCompaniesResponseClear = () => ({
  type: CompaniesTypes.PUT_COMPANIES_RESPONSE_CLEAR,
});

//--------------team DELETE----------------------------
export const deleteCompaniesRequest = (id) => ({
  type: CompaniesTypes.DELETE_COMPANIES_REQUEST,
  id: id,
});

export const deleteCompaniesResponse = (payload) => ({
  type: CompaniesTypes.DELETE_COMPANIES_RESPONSE,
  payload: payload,
});

export const deleteCompaniesResponseClear = () => ({
  type: CompaniesTypes.DELETE_COMPANIES_RESPONSE_CLEAR,
});

//------- Put User ----
export const putUserRequest = (payload, id) => ({
  type: CompaniesTypes.PUT_USER_REQUEST,
  payload: payload,
  id: id,
});

export const putUserResponse = (payload) => ({
  type: CompaniesTypes.PUT_USER_RESPONSE,
  payload: payload,
});

export const putUserResponseClear = () => ({
  type: CompaniesTypes.PUT_USER_RESPONSE_CLEAR,
});
