import { takeLatest, put, call } from "redux-saga/effects";
import CompaniesTypes from "./CompaniesActionTypes";
import {
  deleteCompaniesResponse,
  getCompaniesByIdResponse,
  getCompaniesListResponse,
  postCompaniesResponse,
  putCompaniesResponse,
  putUserRequest,
  putUserResponse,
} from "./CompaniesActions";
import { unAuthorizedData } from "../LoginSaga/LoginActions";
import { axiosDelete, axiosGet, axiosPost, axiosPut } from "../../Utils/axios";

export function* onGetCompaniesList({ payload }) {
  try {
    const { filter, quickFilter = "", keyword = "", count = "" } = payload;

    const sort = filter.sortBy.split("-");

    const url = `company?page=${
      filter.page
    }&filter=${keyword}&role=${quickFilter}&sort_by=${
      sort[0] ? sort[0] : ""
    }&sort=${sort[1] ? sort[1] : ""}&count=${count}`;

    const response = yield call(() => axiosGet(url).then((res) => res.data));
    yield put(getCompaniesListResponse(response));
  } catch (error) {
    yield put(unAuthorizedData(error));
  }
}

export function* onGetByIdCompanies({ id }) {
  try {
    const url = `company/${id}`;
    const response = yield call(() => axiosGet(url).then((res) => res.data));
    yield put(getCompaniesByIdResponse(response));
  } catch (error) {
    yield put(unAuthorizedData(error));
  }
}

export function* onPostCompanies({ payload }) {
  try {
    const url = `company`;
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };
    const response = yield call(() =>
      axiosPost(url, payload, config).then((res) => res.data)
    );
    yield put(postCompaniesResponse(response));
  } catch (error) {
    yield put(unAuthorizedData(error));
  }
}

export function* onPutCompanies({ payload, id }) {
  try {
    const url = `company/${id}`;
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };
    const response = yield call(() =>
      axiosPut(url, payload, config).then((res) => res.data)
    );
    yield put(putCompaniesResponse(response));
  } catch (error) {
    yield put(unAuthorizedData(error));
  }
}

export function* onDeleteCompanies({ id }) {
  try {
    const url = `company/${id}`;

    const response = yield call(() => axiosDelete(url).then((res) => res.data));
    yield put(deleteCompaniesResponse(response));
  } catch (error) {
    yield put(unAuthorizedData(error));
  }
}
export function* onPutUser({ payload, id }) {
  try {
    const url = `admin/${id}`;
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };
    const response = yield call(() =>
      axiosPut(url, payload, config).then((res) => res.data)
    );
    yield put(putUserResponse(response));
  } catch (error) {
    yield put(unAuthorizedData(error));
  }
}

export function* CompaniesWatcherSaga() {
  yield takeLatest(
    CompaniesTypes.GET_COMPANIES_LIST_REQUEST,
    onGetCompaniesList
  );
  yield takeLatest(
    CompaniesTypes.GET_COMPANIES_BY_ID_REQUEST,
    onGetByIdCompanies
  );
  yield takeLatest(CompaniesTypes.POST_COMPANIES_REQUEST, onPostCompanies);
  yield takeLatest(CompaniesTypes.PUT_COMPANIES_REQUEST, onPutCompanies);

  yield takeLatest(CompaniesTypes.DELETE_COMPANIES_REQUEST, onDeleteCompanies);
}
